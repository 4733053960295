/* Mobile only */
@media only screen and (min-width: 300px) and (max-width: 767px) {
  #enterpriseLogo {
    margin-right: 0px;
  }

  .hideOnMobile {
    display: none;
  }

  .form-check {
    margin-bottom: 14px;
  }

  .termsAndCaptchaContainer {
    flex-direction: column !important;
  }

  .verifiyEmail {
    max-width: 80% !important;
  }

  #workoutAssignerTabs .nav.nav-tabs {
    width: 100% !important;
  }

  .editCardDetailButton {
    /* position: relative;
      left: 85px;
      bottom: 130px; */
    width: 100%;
  }

  .editCardNote {
    width: 85% !important;
    margin-left: -10px;
  }

  #fileUploadedDiv {
    margin-left: 9px !important;
  }

  #feedbackForm.MISSING_EXERCISE .modal-footer {
    margin-bottom: 8px;
  }

  #feedbackForm.MISSING_EXERCISE {
    max-width: 86% !important;
    top: 50% !important;
  }

  /* #exerciseFilters {
      margin-right: 18px;
    } */

  .editorPanelTopRow .col {
    margin-top: 5px !important;
  }

  /* .datePicker {
    margin-right: 7px !important;
  } */

  .verticalCenter {
    line-height: 30px !important;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .fullWidth {
    max-height: max-content;
    width: 100% !important;
  }

  #clientEditorList {
    margin-left: 0px !important;
  }

  #enterpriseEditorList {
    margin-left: 0px !important;
  }

  #clientDetailWorkoutSummary {
    padding: 0px !important;
  }

  #clientDetailAssignCoach {
    padding: 0px !important;
  }

  .clientDetailContainer {
    margin-top: 30px;
  }

  .enterpriseDetailContainer {
    margin-top: 30px;
  }

  .assignerModal .modal-content {
    width: initial;
  }

  .workoutDaysHeader {
    display: grid;
  }

  #clientEditorList .decPadding {
    padding: 8px !important;
  }

  #enterpriseEditorList .decPadding {
    padding: 8px !important;
  }

  #clientEditorList {
    margin-left: 8px;
  }

  #enterpriseEditorList {
    margin-left: 8px;
  }

  .clientEditor .nav.nav-tabs {
    justify-content: space-around;
    border-bottom: 1px solid #eee;
    margin-bottom: 0px !important;
    padding-bottom: 16px !important;
  }

  .enterpriseEditor .nav.nav-tabs {
    justify-content: space-around;
    border-bottom: 1px solid #eee;
    margin-bottom: 0px !important;
    padding-bottom: 16px !important;
  }

  #clientEditorListContainer .nav-tabs > .nav-item > .nav-link {
    margin: 0;
    margin-right: 5px;
    padding: 0px 0px !important;
    line-height: 1.5;
  }

  #enterpriseEditorListContainer .nav-tabs > .nav-item > .nav-link {
    margin: 0;
    margin-right: 5px;
    padding: 0px 0px !important;
    line-height: 1.5;
  }

  #mobileStyleCreatePractitioner {
    margin-top: 12px;
    flex-direction: column;
  }

  #mobileStyleLink {
    margin-left: 0 !important;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .signupBody .logo {
    width: 220px;
    margin-top: 64px;
    margin-bottom: 0;
  }

  .loginBody,
  .signupBody,
  .profileBody {
    height: 100vh;
    margin: auto;
  }

  .exerciseInfo {
    font-size: 0.45rem !important;
  }

  .videoReplayButton {
    top: -165px !important;
  }

  .videoReplayButton:hover {
    background-color: transparent !important;
  }

  /* .exerciseCard .details {
      max-width: 100%;
    } */

  .exerciseCard .card-title .exerciseName {
    margin-right: 0px;
    padding-left: 5px;
    font-size: 0.5rem;
  }

  .exerciseCard .level {
    padding-right: 5px !important;
  }

  .exerciseCard .level > p {
    font-size: 0.5rem !important;
  }

  .exerciseCardLevel {
    font-size: 0.5rem !important;
  }

  .summaryContentHeading {
    font-size: 0.65rem !important;
  }

  .workoutDayArrow {
    margin-right: -20px !important;
  }

  .filterPadding {
    margin: 8px 0 !important;
    padding: 0 !important;
  }

  .signupBody .loginCard {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: auto;
    box-shadow: none !important;
  }

  .clientEditor h3.card-title {
    padding-left: 10px;
  }

  .enterpriseEditor h3.card-title {
    padding-left: 10px;
  }

  .dropdown-style {
    margin-top: 12px !important;
  }

  .dropdown-menu {
    background-color: white !important;
    border: solid 0.5px lightgrey !important;
  }

  .button-small-margin {
    margin-right: 18px;
    max-width: 100% !important;
    text-align: center !important;
  }

  #workoutDesigner {
    min-height: 650px;
  }

  .selectedExercisesContainer {
    margin-top: 40px;
    min-height: 500px !important;
    padding: 8;
    overflow-y: auto;
    overflow-x: auto;
    right: 0;
    top: 0;
  }

  /* .exerciseEditCard {
      max-width: 75%;
    } */

  .exerciseEditCard .index {
    position: absolute;
    left: -18px;
    margin-left: -8px;
    margin-bottom: 8px;
  }

  .exerciseEditCard .videoContainer {
    position: relative;
    left: -18px;
  }

  .dayText {
    font-size: 1.6rem;
  }

  .clientDetailCard {
    width: 100%;
  }

  .clientEditor h3.card-title {
    padding-left: 10px;
  }

  .enterpriseEditor h3.card-title {
    padding-left: 10px;
  }

  .exerciseEditCard {
    flex-direction: column;
    width: initial;
  }

  .exerciseDetailsInputs {
    min-width: 250px;
    margin: 16px 0;
  }

  #assignerTopRow {
    padding: initial 32px;
  }

  #assignerTopRow .assignButton {
    margin: 8px 0;
  }

  #errorsModal {
    margin-top: 36px !important;
  }

  #errorsModal .modal-body {
    padding-top: 0;
  }

  #errorsModal .modal-footer {
    padding-top: 8px;
    padding-bottom: 0;
  }

  .exerciseEditorModal,
  #feedbackForm.MISSING_EXERCISE {
    margin-top: 36px !important;
  }

  .exerciseEditorModal .modal-body,
  #feedbackForm.MISSING_EXERCISE .modal-body {
    padding-top: 0;
  }

  .exerciseEditorModal .modal-footer,
  #feedbackForm.MISSING_EXERCISE .modal-footer {
    padding-top: 8px;
    padding-bottom: 0;
  }

  #posterUploadContainer {
    padding-left: 29px;
  }

  .bulkUploadButtonContainer {
    position: relative;
  }
  #programForm {
    padding: 10px 20px;
    margin-right: 0 !important;
  }
  .programPackageFieldWidth {
    width: 100% !important;
  }
  /* Designer Responsive Css starts here */
  .filterQueryTextPadding {
    padding: 0;
  }
  #searchExerciseFilter {
    margin: 0 !important;
  }
  .filtersCollapsableButtonContainer {
    margin: 8px 0 !important;
    padding: 0 !important;
    justify-content: center;
  }
  #filterContainer {
    padding: 0 !important;
  }
  #workoutDesignerDetails {
    padding: 0 !important;
    height: 36px;
  }
  #deisgnerTopRow {
    padding: 0 !important;
  }
  #deisgnerTopRow #exerciseFiltersContainer {
    width: 100%;
  }
  #exerciseFilters {
    width: 100%;
    margin: 0;
  }
  #saveFilterButton {
    text-align: center;
    margin-top: 8px;
  }
  #draftFilterButton {
    text-align: center;
    margin-top: 8px;
  }
  #designerContainer {
    flex-direction: column;
  }
  .workoutDesignerListContainer {
    width: 280px;
    max-width: initial;
  }
  .exerciseEditCard .title {
    margin-left: 8px;
    font-size: 12px !important;
    max-width: 120px !important;
  }
  .feedbackButton {
    top: calc(50% + 120px);
  }
  .floatingButton {
    bottom: 60px;
  }
  /* Designer Responsive Css Ends here */
  /* Assigner Responsive Css Starts here */
  .mobileFullWidth {
    width: 100% !important;
  }
  #repeatDaysToggle {
    width: 100%;
    text-align: start;
  }
  #searchAssignerInput .form-group {
    width: 100%;
  }
  .addDayAssignerCard {
    padding: 6px !important;
  }
  .nav-item {
    padding: 0;
    max-width: 100px;
  }
  .nav-tabs {
    padding: 0;
  }
  .assignedWorkoutText {
    max-width: 35%;
  }
  .workout-list-button {
    flex: 1;
    max-width: none !important;
  }
  #workoutTypeSelector {
    width: 100%;
    flex-direction: column;
  }
  #workoutTypeSelector .form-group {
    width: 100%;
  }
  .topClientList {
    padding: 0 !important;
  }
  #workoutDaySelect {
    margin-right: 0;
    width: 100%;
  }
  .workoutDaysList {
    height: auto !important;
    max-height: none !important;
  }
  #forMobileView {
    margin: 8px 0 !important;
  }
  .clientSMCard,
  .selectedCard {
    height: 80px !important;
  }
  #forMobileView .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
  .assignerModal {
    width: 94vw;
  }
  .clientDetailContainer {
    min-height: auto;
  }
  .enterpriseDetailContainer {
    min-height: auto;
  }
  .mobilOverFlowHidden {
    overflow: hidden !important;
  }
  .mobileHeightInitial {
    height: initial !important;
  }
  .mobileAlignItemsNone {
    align-items: unset !important;
  }
  .marginAuto {
    margin: auto !important;
  }

  /* Assigner Responsive Css Ends here */
}
