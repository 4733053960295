body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Autocomplete styling Starts here------------------------------------------------------ */
.CUSTOM__map-maker-icon {
  color: #fd6c6c;
}

.CUSTOM__github-icon {
  font-size: 24px;
}

.CUSTOM__github-link {
  color: #262626;
  font-size: 20px;
}

.CUSTOM__github-link:hover,
.CUSTOM__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.CUSTOM__suggestion-icon {
  margin-right: 8px;
}

.CUSTOM__search-bar-container {
  width: 100%;
}

.CUSTOM__search-input-container {
  position: relative;
}

.CUSTOM__search-input,
.CUSTOM__search-input:focus,
.CUSTOM__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.CUSTOM__clear-button,
.CUSTOM__clear-button:active,
.CUSTOM__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.CUSTOM__autocomplete-container {
  max-width: 328px;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.CUSTOM__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.CUSTOM__suggestion-item--active {
  background-color: #fafafa;
}

.CUSTOM__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.CUSTOM__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.CUSTOM__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.CUSTOM__error-message {
  color: red;
  text-align: left;
}

.CUSTOM__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

/* Autocomplete styling Ends here------------------------------------------------------ */

/*------------------------ONBOARDING STYLES START--------------------*/

.onboardingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 92vh;
  border-top: solid 1px lightgrey;
  background-color: white;
}

.onboardingLeftSide {
  flex: 0.15 1;
}

.onboardingMainContent {
  flex: 0.6 1;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column;
  box-shadow: 10px 0 5px -2px #f0f0f0;
}

.onboardingRightSide {
  position: relative;
  flex: 0.25 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.pageCount {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 150px;
  padding: 15px 15px 40px 40px;
  font-size: 52px;
  font-weight: 600;
  color: lightgrey;
  background-color: #f8f8f8;
}

.onboardingButtonContainer {
  display: flex;
  padding-bottom: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.goBackContainer {
  display: flex;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  color: gray;
  padding-right: 44px;
}

.onBoardingHeading {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
}

.onBoardingSubHeading {
  font-size: 20px;
  font-weight: 500;
  color: #802520;
}

.goBackContainer:hover {
  color: black;
  cursor: pointer;
}

.onBoardingSectionHeader {
  color: #802520;
  text-align: left;
  padding-bottom: 20px;
}

.onBoardingSubHeaderContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 10px;
}

.educationAndCertificationsContainer {
  flex-direction: column;
  padding-right: 26px;
}

.educationAndCertificationsInnerContainer {
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.servicesContainer {
  flex-direction: column;
  padding-right: 26px;
}

.documentsContainer {
  flex-direction: column;
  padding-right: 26px;
}

.documentsSubHeader {
  margin-bottom: 10px;
  margin-top: 44px;
}

.servicesInnerContainer {
  display: flex;
  flex: 1 1;
  margin-top: 10px;
  flex-direction: column;
}

.documentsInnerContainer {
  display: flex;
  flex: 1 1;
  margin-top: 10px;
  flex-direction: column;
}

.addNewtext {
  color: #707070;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
}

.educationAndCertificationsAddNew {
  margin-top: 8px;
}

.addNewtext:hover {
  cursor: pointer;
  opacity: 0.5;
}

.educationCardsContainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
}

.educationCard {
  display: flex;
  position: relative;
  outline: none;
  border: none !important;
  margin: 10px 10px;
  width: 30%;
  min-width: 250px;
  background-color: #f0f0f0 !important;
}

.educationCancel {
  position: absolute;
  top: 14px;
  right: 16px;
}

.educationCardMainText {
  width: 88%;
  display: flex;
  flex-direction: column;
  color: #802520;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.educationCardSecText {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

.educationEditCancel {
  position: absolute;
  top: 8px;
  right: 8px;
}

.serviceEditCancel {
  position: absolute;
  top: 8px;
  right: 8px;
}

.educationEdit {
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.educationCardContent {
  flex: 1 1;
  padding: 16px;
  min-height: 116px;
  display: flex;
  flex-direction: column;
}

.educationReferenceLink {
  margin-top: 16px !important;
  font-style: italic;
  font-weight: bold !important;
}

.educationFieldsContainer {
  padding: 20px;
  width: 400px;
}

.serviceFieldsContainer {
  padding: 20px 40px 40px 40px;
  width: 400px;
}

.serviceField {
  padding-bottom: 16px;
}

.educationField {
  padding-bottom: 16px;
}

.certificationEdit {
  text-align: end;
  bottom: 6px;
  right: 6px;
}

.certificationCardsContainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
}

.selectAllText {
  color: #03a9f4;
  font-size: 16px;
}

.unselectAllText {
  font-size: 16px;
  color: grey;
}

.selectAllText:hover,
.unselectAllText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.onboardingServicesList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
}

.customServiceChip
  .MuiSvgIcon-root.MuiChip-deleteIcon.MuiChip-deleteIconColorPrimary {
  width: 14px;
  height: 14px;
}

.fileUploaderContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.uploaderContainer {
  flex: 0.45 1;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.uploadManager {
  width: 100%;
}

.uploadManager .uploadTitle {
  font-size: 14px;
  min-width: 80%;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.uploaderText {
  font-size: 14px;
  min-width: 80%;
  height: 40px;
  margin-right: 16px;
  background-color: #f0f0f0;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.aboutTextArea:hover {
  border-color: black !important;
}

.aboutTextArea:focus {
  border-color: #802520 !important;
  border-width: 2px;
}

.fileUploadParent {
  display: flex;
  flex-direction: column;
}

.filePickerLabel {
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  background: #f0f0f0;
}

.filePickerLabel:hover {
  cursor: pointer;
}

.proPicContainer {
  width: 150px;
  padding-top: 150px;
  position: relative;
  cursor: pointer;
  border: 3px solid white;
  box-shadow: 2px 2px 2px 2px #aba9a9;
  border-radius: 50%;
  box-sizing: content-box;
}

.proPicContainer img {
  /* object-position: top; */
  background-color: #e0e0e0;
}

.proPicLabel {
  position: absolute;
  top: 45%;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  color: grey;
}

.onBoardingTabs {
  display: none;
}

.onboardMenuButton {
  display: none !important;
}

.onboardingFieldContainer {
  flex: 1 1;
  width: 90%;
}

.rightSideInnerContainer {
  width: 75%;
}

.onboardingMainButton {
  margin-bottom: 20px !important;
  height: 50px;
}

.letsCreateText {
  padding-right: 6px;
  font-weight: 600;
}

.fieldContainer {
  padding: 10px 20px;
  padding-left: 0;
}

.MuiOutlinedInput-input {
  border-bottom: none !important;
}

.onboardingServicesList .MuiChip-label {
  font-weight: 600;
}

.mg-rt-32 {
  margin-right: 32px;
}

.mg-tp-32 {
  margin-top: 32;
}

.mg-tp-bt-32 {
  margin: 32px 0 !important;
}

.mg-tp-bt-16 {
  margin: 16px 0 !important;
}

.marginTop16 {
  margin-top: 16px !important;
}

.programDropdownHeight .fortisFormaDropDownSelect__placeholder,
.programDropdownHeight .fortisFormaDropDownSelect__single-value {
  font-size: 14px;
}

.extrasChips .MuiOutlinedInput-adornedStart {
  padding-left: 8px;
}

.previewButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.stepOneOnboardMainContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
}

.stepOneOnboardInnerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.certificateImgContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.certificateImg {
  width: 115px;
  height: 150px;
  object-fit: contain;
}

.orText {
  width: 100%;
  display: flex;
  justify-content: center;
  color: grey;
  padding-bottom: 16px;
}

.onBoardingServicesSubHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 10px;
}

/*-------Preview Styles-------*/
.pd-top-16 {
  padding-top: 16px;
}

.pd-top-44 {
  padding-top: 44px;
}

.pd-bottom-16 {
  padding-bottom: 16px;
}

.mg-left-16 {
  margin-left: 16px;
}

.trainersContainer {
  height: 70vh;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.trainerCardRadius {
  border-radius: 6px;
}

.trainerCard {
  margin: 0;
  width: 400px;
  min-height: 520px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.trainerCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.trainerCardContentContainer {
  padding: 8px;
}

#exampleProgram .trainerCard,
#exampleProgram .trainerCardContentContainer {
  padding-top: 0;
}

#exampleProgram .trainerProgram:nth-child(2) {
  margin-top: 0;
}

.trainerProfilePic {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain;
}

.trainerProfilePic img {
  object-position: top;
  background-color: #e0e0e0;
}

.trainerChip {
  height: 20px !important;
  font-weight: 500;
  margin: 0px 2px;
  border: 1px solid #800520 !important;
}

.trainerChip span {
  padding: 0 6px !important;
}

.trainerInfoTitle {
  color: grey;
}

.certificateIcon {
  height: 14px !important;
  width: 14px !important;
  margin-left: 4px;
  margin-bottom: -3px;
  cursor: pointer;
}

.trainerProfileTopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trainerMainDetails {
  margin-left: 16px;
}

.trainerProfileButtons {
  display: flex;
  flex: 1 1;
  align-items: flex-end;
  justify-content: space-between;
}

.educationList {
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  -webkit-margin-before: 0em;
          margin-block-start: 0em;
  -webkit-margin-after: 0em;
          margin-block-end: 0em;
}

.pricingContainer {
  flex: 1 1;
  text-align: left;
  align-self: center;
}

.programPrice {
  display: flex;
  align-items: baseline;
}

.programIncl {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: -6px;
}

.trainerProgramNameContainer {
  flex: 1 1;
  display: flex;
  align-items: center;
}

.greyColor {
  color: grey;
}

.redColor {
  color: #800520;
}

.contactFormField {
  width: 100%;
  margin-top: 16px !important;
}

.trainerPrograms {
  padding: 8px 8px;
}

.trainerProgram {
  margin-top: 42px;
  margin-bottom: 4px;
}

.trainerProgram:nth-child(2) {
  margin-top: 12px;
}

.trainerPackage {
  border: 1px solid #e0e0e0;
  padding: 4px 16px;
  margin-top: 8px;
  border-radius: 4px;
  left: -2px;
  position: relative;
}

.letterSpacingInc {
  letter-spacing: 1px !important;
}

.programsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#programsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;
}

#programsHeader button {
  margin-right: 32px;
}

.packageNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#programsHeader .onBoardingSectionHeader {
  padding: 0;
  margin: 0;
}

/*Only Mobile*/
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .onboardingMainContent {
    display: block !important;
    flex: initial !important;
    overflow-y: initial !important;
  }
  .onboardingButtonContainer {
    padding: 0 !important;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .onboardingMainButton {
    height: 60px !important;
    margin-bottom: 0 !important;
    width: 50%;
    border-radius: 0 !important;
  }

  .rightSideInnerContainer {
    width: 100%;
  }

  .pageCount {
    display: none;
  }

  .onboardingContainer {
    height: initial !important;
    flex-direction: column !important;
  }

  .hideItem {
    display: none;
  }

  .onboardingRightSide {
    flex: initial !important;
  }

  .onboardingFieldContainer {
    flex: 1 1;
    width: 100%;
  }

  .letsCreateText {
    display: block;
    font-size: 28px !important;
    font-weight: 600;
  }

  .goBackContainer {
    text-align: left !important;
    justify-content: flex-start !important;
    padding-left: 20px;
  }

  .onBoardingSectionHeader {
    padding-left: 20px;
    font-size: 1.5rem;
  }

  .onBoardingSubHeaderContainer {
    padding-left: 20px;
  }

  .educationCardsContainer {
    flex-direction: column;
  }

  .educationAndCertificationsContainer {
    padding-right: 0 !important;
  }

  .educationCard {
    width: 96% !important;
  }

  .educationFieldsContainer,
  .serviceFieldsContainer {
    max-width: 300px;
  }

  .servicesContainer {
    padding-right: 0 !important;
    padding-left: 24px !important;
  }

  .fileUploaderContainer {
    flex-direction: column !important;
    padding-left: 16px;
  }

  .documentsContainer {
    padding-right: 0 !important;
  }

  .uploaderText {
    margin-bottom: 5px;
  }

  .uploaderContainer {
    margin-top: 20px;
  }

  .onboardingServicesList {
    padding-left: 16px;
  }

  .onboardMenuButton {
    display: block !important;
  }

  .onboardMenu.visible {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
  }

  .onboardMenu {
    position: fixed;
    background: #fafafa;
    z-index: 1000;
    position: fixed;
    will-change: transform;
    transition: all 0.25s ease-in;
  }

  .imageContainer {
    display: none;
  }

  .proPicContainer {
    width: 200px;
    height: 100px;
    padding-top: 100px;
  }

  .proPicLabel {
    top: 43%;
    font-size: 1rem;
  }

  .educationAndCertificationsContainer {
    flex-direction: column;
  }

  .educationAndCertificationsInnerContainer {
    max-width: 100vw;
  }

  .servicesInnerContainer {
    max-width: 100vw;
  }

  .documentsInnerContainer {
    max-width: 100vw;
  }

  .servicesContainer {
    flex-direction: column;
  }

  .documentsContainer {
    flex-direction: column;
  }

  .StepFourUploadContainer {
    flex-direction: column;
  }

  .filePickerLabel {
    padding: 20px;
  }

  #onBoarding .MuiGrid-container {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .drawerListItem {
    padding-left: 5px !important;
  }

  .stepOneOnboardMainContainer {
    flex-direction: column;
  }

  .stepOneOnboardInnerContainer {
    flex-direction: column;
  }

  .trainerCard {
    width: initial !important;
  }

  #programsHeader {
    padding-left: 24px !important;
  }
}

/*Only IPad*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .onboardMenuButton {
    display: block !important;
  }

  .onboardMenu.visible {
    -webkit-transform: translateX(-300px);
            transform: translateX(-300px);
  }

  .onboardMenu {
    position: fixed;
    background: #fafafa;
    z-index: 1000;
    position: fixed;
    will-change: transform;
    transition: all 0.25s ease-in;
  }

  .imageContainer {
    display: none;
  }

  .proPicContainer {
    width: 200px;
    height: 100px;
    padding-top: 100px;
  }

  .proPicLabel {
    top: 43%;
    font-size: 1rem;
  }

  .educationAndCertificationsContainer {
    flex-direction: column;
  }

  .educationAndCertificationsInnerContainer {
    max-width: 100vw;
  }

  .servicesContainer {
    flex-direction: column;
  }

  .documentsContainer {
    flex-direction: column;
  }

  .servicesInnerContainer {
    max-width: 100vw;
  }

  .documentsInnerContainer {
    max-width: 100vw;
  }

  .StepFourUploadContainer {
    flex-direction: column;
  }

  .filePickerLabel {
    padding: 20px;
  }

  #onBoarding .MuiGrid-container {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .stepOneOnboardMainContainer {
    flex-direction: column;
  }

  .stepOneOnboardInnerContainer {
    flex-direction: column;
  }

  .trainerCard {
    width: initial !important;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
}

:focus {
  outline: none !important;
}

:active {
  outline: none !important;
}

#noty_layout__topRight {
  top: 74px !important;
}

/* my styling */
.descBtn {
  background: #800520;
  color: #fff;
  padding: 5px;
  margin: 0 5px 0;
  align-self: baseline;
  border-radius: 5px;
  font-size: 12px;
}
.lastMsgArea span {
  display: block;
}
.unreadMsg {
  position: absolute;
  font-weight: bold;
  background: #f9e9e8;
  border: 1px solid #802520;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  top: -17px;
  right: -17px;
}
.msgRelBtn {
  position: relative;
  width: -webkit-max-content;
  width: max-content;
}
.timerModal .MuiDialog-paperWidthSm {
  background-color: transparent;
  box-shadow: none;
}

.timerModal .MuiIconButton-colorInherit {
  width: 49px;
  margin-bottom: 30px;
  height: 49px;
  background: #fff;
  padding: 0;
  margin-left: auto;
}

.timerModal .CircularProgressbar {
  margin-bottom: 30px;
  width: 195px;
}

.timerModal .timeCount {
  font-size: 65px;
  margin-bottom: 20px;
  color: #fff;
}

.btnGroup button {
  margin: 0 10px;
}

.replayBtn .MuiSvgIcon-root {
  width: 58px;
  font-size: 58px;
}

.playBtn .MuiSvgIcon-root {
  width: 30px;
  font-size: 30px;
}

.draftsTabsBtn .btn.btn-primary,
#workoutTypeSelector #draftFilterButton .btn.btn.btn-primary,
#workoutTypeSelector #draftFilterButton .btn.btn-primary:hover {
  background: #cdcbcb;
  background-color: #cdcbcb;
  background-image: linear-gradient(
    to bottom left,
    #cdcbcb,
    #cdcbcb,
    #cdcbcb
  ) !important;
}

#workoutTypeSelector #draftFilterButton {
  margin-right: 10px;
}

.draftsTabsBtn .btn.btn-primary.active,
#workoutTypeSelector #draftFilterButton .btn.btn.btn-primary.active {
  background: #800520;
  background-image: linear-gradient(
    to bottom left,
    #800520,
    #800520,
    #800520
  ) !important;
}

#saveFilterButton {
  margin-left: 10px;
}

/* #771823 */
.circuitDisplay {
  font-size: 12px;
  margin-left: 20px;
  line-height: 26px;
  color: #800520;
}

.circuitDisplay span {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #800520;
  color: #fff;
  flex-shrink: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.dpArea {
  max-width: 50px;
}

.delArea {
  max-width: 30px;
}

.emailArea {
  min-width: 150px;
  padding: 0 5px;
}

.repeatBtn.MuiIconButton-root {
  margin-top: -2;
  margin-left: 4;
  color: #800520;
  font-size: 12px;
  border: 1px solid #800520;
  background: #fbf1f3;
  padding: 1px 6px;
  border-radius: 18px;
}

.repeatCircuitModal h1 {
  color: #800520;
  font-weight: 700;
  text-align: center;
  font-size: 19px;
  max-width: 500px;
  margin: 0 auto 20px;
  line-height: 25px;
}

.repeatCircuitModal input {
  display: block;
  margin: 0 auto 20px;
}

.repeatCircuitModal input.noInput {
  border: 1px solid #000 !important;
  max-width: 100px;
}

.repeatCircuitModal input {
  width: 100%;
  max-width: 500px;
  border: 1px solid #ccc !important;
  height: 38px;
}

.repeatCircuitModal {
  max-width: 600px;
}

.sharePdfFlex {
  flex-direction: row !important;
  margin-top: 20px;
}

.sharePdfFlex .MuiFormControlLabel-root {
  flex: 1 1;
}

.sharePdfFlex .MuiFormControlLabel-root:nth-of-type(2) {
  margin-right: 0;
}

.multiSelect {
  width: 100%;
}

.css-yk16xz-control {
}

.multiSelect .css-1pahdxg-control:hover {
  border-color: #800520;
}

.multiSelect .css-1pahdxg-control {
  box-shadow: 0 0 0 1px #800520;
}

.multiSelect .css-1laao21-a11yText,
.multiSelect .css-1pahdxg-control:focus {
  box-shadow: 0 0 0 1px #800520 !important;
  border-color: #800520 !important;
}

.multiCheck {
  position: relative;
  border: 1px solid #222a42;
}

/* .multiCheck{font-size: 20px;} */
.multiCheck:checked {
  accent-color: white;
}

.customListing ul {
  /*height: auto !important;*/
  padding: 0;
  justify-content: space-between;
}

.customListing ul li {
  width: 30%;
}

.customListing ul li:nth-last-of-type(1) {
  width: 24px;
}

.customListing ul li:nth-of-type(1) {
  justify-content: flex-start;
}

.customListing ul li:nth-of-type(1) .clientCardAvatar {
  margin-right: 10px;
}

.customListing {
  /*position: relative !important;*/
  /*height: auto !important;*/
  width: 100% !important;
  /*top: 0 !important;*/
  /*left: 0 !important;*/
}

.customListing ul li:nth-of-type(1) p {
  text-align: center;
}

.clientSMCardUpdated {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  margin: 0;
}

.clientWorkModal {
  position: relative;
}

.clientWorkModal .MuiDialog-paperScrollPaper > .MuiButtonBase-root {
  position: fixed;
  top: 50%;

  background: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.clientWorkModal
  .MuiDialog-paperScrollPaper
  > .MuiButtonBase-root:nth-of-type(1) {
  left: 20%;
}

.clientWorkModal
  .MuiDialog-paperScrollPaper
  > .MuiButtonBase-root:nth-of-type(2) {
  right: 20%;
}

.clientWorkModal
  .MuiDialog-paperScrollPaper
  > .MuiButtonBase-root
  .MuiIconButton-label {
  margin-right: -7px;
}

.clienCircuitModal .MuiDialog-paperWidthLg {
  background: transparent;
  box-shadow: none;
}

.clienCircuitModal h2 {
  font-size: 3.5rem;
  color: #fff;
  font-weight: 900;
  line-height: 2.5rem;
  text-align: center;
}

.clienCircuitModal .circuitCont .MuiSvgIcon-fontSizeLarge {
  font-size: 10rem;
  margin: 0 auto 20px;
  color: #fff;
  display: block;
}

.clienCircuitModal .circuitCont {
  text-align: center;
}

.clienCircuitModal div.nextUp {
  background: #802520;
  border-radius: 5px;
  text-align: center;
  padding: 10px 10px;
}

.clienCircuitModal div p {
  color: #fff;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 0;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 65%) !important;
}

.pricePlanModal h5 {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 600;
  margin: 20px 0px 0px 0px;
}

.pricePlanModal h4 {
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
}

.pricePlanModal label {
  display: block;
  width: 80%;
  margin: 0 auto 20px;
  position: relative;
}

.pricePlanModal label input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.pricePlanModal label > span {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  background: #820520;
  color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  padding: 7px 10px;
}

.pricePlanModal label > span span {
  display: block;
  font-weight: 300;
}

.pricePlanModal label input:checked span {
  background: #d5b0b8;
  color: #820520;
}

.trialModal h5 {
  color: #820520 !important;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
  margin: 20px 0px 0px 0px;
}

.trialModal h4 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.2rem;
  text-align: center;
  font-weight: 100;
}

.trialModal label {
  display: block;
  width: 50%;
  margin: 0 auto 20px;
  position: relative;
}

.trialModal label span {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  background: #820520;
  color: #fff;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  padding: 7px 10px;
}

.trialModal label span span {
  display: block;
  font-weight: 300;
}

.closeModal {
  width: -webkit-max-content;
  width: max-content;
  /* position: absolute;
          top: 0;
          right: 0; */
  margin: 0 auto;
  z-index: 9999;
  background: transparent;
  border: none;
  padding: 0;
}

.closeModal .MuiSvgIcon-fontSizeSmall {
  font-size: 2rem;
  color: #fff;
}

.closeModalBtn {
  background: transparent;
  color: #fff;
  margin-top: 10px;
  border: none;
  padding: 0;
  font-weight: 900;
  font-size: 1.2rem;
  letter-spacing: 3px;
}

.closeModalBtn:hover {
  cursor: pointer;
}

.modalArrow.Mui-disabled {
  opacity: 0.5;
  cursor: none;
}
.strippe-modal-wrap {
  position: relative;
  margin: 15px;
}
.strippe-modal-wrap input[type="checkbox"] {
  accent-color: rgb(89, 25, 22);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 32px;
  left: 10px;
}
input[type="checkbox"]:checked + .staff-card {
  background: #f1e8e9;
}
.staff-card-detailed .staff-card {
  background: #f1e8e9 !important;
}
.staffStripeCards .staff-card {
  background: #ffffff;
  box-shadow: 0px 0px 13.0899px rgba(0, 0, 0, 0.08);
  border-radius: 11.2199px;
  padding-inline: 20px;
}
.staffStripeCards .staff-card-head {
  display: flex;
  align-items: center;
  padding-left: 20px;
  grid-gap: 10px;
  gap: 10px;
  padding-block: 15px;
}
.staffStripeCards h5 {
  font-weight: 700;
  color: #820520 !important;
  font-weight: 500;
  padding-block: 15px;
  text-align: left;
}
.staffStripeCards .card-inf-flex {
  flex-wrap: wrap;
  display: flex;
}
.staffStripeCards .flex-item {
  width: 33.333%;
  margin-bottom: 10px;
}
.staffStripeCards .flex-item p:nth-child(even) {
  font-weight: 700;
}
.staffStripeCards .card-imgs {
  width: 40px;
  height: 40px;
}
.staffStripeCards .stripedelete-btn {
  color: rgb(89, 25, 22);
  display: flex;
  align-items: center;
  margin-left: auto;
}
/*@media (max-width: 575px) {*/
/*  .workoutSessionExerciseEditContainer {*/
/*    flex-direction: column;*/
/*  }*/

/*  .workoutSessionExerciseEditContainer #timeContainer {*/
/*    margin-top: 20px;*/
/*  }*/

/*  .workoutSessionExerciseEditContainer .numberButtonEditDetailModal {*/
/*    margin-left: 8px !important;*/
/*  }*/

/*  #sliderTextValues .MuiTypography-body2 {*/
/*    font-size: 14px !important;*/
/*  }*/

/*  .customListing ul li:nth-of-type(1) {*/
/*    flex-direction: column;*/
/*  }*/

/*  .customListing ul li:nth-of-type(1) .clientCardAvatar {*/
/*    margin-right: 0;*/
/*  }*/

/*  .customListing ul li:nth-of-type(1) .MuiAvatar-root {*/
/*    width: 33px;*/
/*    height: 33px;*/
/*  }*/

/*  .customListing ul li:nth-of-type(1) p {*/
/*    margin-bottom: 0;*/
/*    font-size: 12px;*/
/*  }*/
/*}*/

/*@media (max-width: 480px) {*/
/*  .customListing ul li:nth-of-type(1) .MuiAvatar-root {*/
/*    width: 27px;*/
/*    height: 27px;*/
/*  }*/
/*}*/

/* my styling ended */

@media (max-width: 998px) {
  .btn {
    padding: 11px 25px !important;
  }
}

@media (max-width: 1000px) {
  .wrapper .navbar {
    z-index: 10000001;
  }
}

.noty_type__confirm.noty_theme__semanticui {
  background-color: white !important;
}

.flexRow {
  display: flex;
  flex-direction: "row";
}

.whiteText {
  color: white !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.imagePreviewContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewContentContainer {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  max-height: 80vh;
  max-width: 80%;
}

.previewModalImageContainer {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previewModalPDFContainer {
  max-width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
}

#previewCloseButton {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
}

#previewClose {
  position: relative;
  bottom: 55px;
}

#previewImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

#uploadImageLabelText {
  margin-top: 4px !important;
}

.wrongPlacePageCntainer {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}

.outlinedCard {
  border: 1px solid #efefef;
  border-radius: 6px;
  background-color: white;
}

.outlinedCard#completeProfileCard {
  border-color: #f44336;
}

.outlinedCard#completeProfileCard:hover {
  border-color: #800520;
}

.iconLabel {
  font-size: 8px;
  margin-top: 8px;
}

.exerciseEditCardMultiIcon {
  font-size: 18px;
}

.welcomeMessage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.welcomeMessage .redirect {
  margin-top: 16px;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.8);
}

#root {
  width: 100vw;
  min-height: 100vh;
}

#loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 300px;
  margin: auto;
}

.formaRed {
  color: #800520 !important;
}

.tooltip {
  font-weight: bold;
  opacity: 1 !important;
}

.tooltip .arrow:before {
  border-bottom-color: black !important;
  border-top-color: black !important;
}

.tooltipInnerBody {
  color: white !important;
  background: black !important;
  opacity: 1 !important;
}

.shake {
  -webkit-animation-duration: 0.5s !important;
          animation-duration: 0.5s !important;
}

.error {
  color: red !important;
}

.center {
  text-align: center;
}

html,
body {
  overflow-x: hidden;
  background: white;
  position: relative;
}

input {
  border: 0 !important;
  border-bottom: 1px solid !important;
  border-radius: 0 !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

textarea {
  min-height: 42px !important;
  resize: vertical !important;
  max-height: 150px !important;
}

.form-control[disabled] {
  opacity: 0.5 !important;
  background: white !important;
  color: black !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-image: initial !important;
  background-color: initial !important;
}

.modal-backdrop.show {
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

a {
  text-decoration: none;
}

.modal-dialog {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60%;
  }
}

@media (max-width: 576px) {
  .staffStripeCards .flex-item {
    width: 50%;
  }
  .staffStripeCards h5 {
    font-size: 1rem;
  }
  .stripeHeader h4 {
    font-size: 1rem;
  }
  .staff-card-head p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .staffStripeCards .staff-card-head .stripedelete-btn span {
    display: none;
  }
  .staffStripeCards .staff-card-head {
    grid-gap: 5px;
    gap: 5px;
    padding-left: 10px;
  }
  .staffStripeCards .flex-item {
    width: 100%;
  }
  .staff-card-head p {
    font-size: 10px;
    margin: 0;
  }
  .staffStripeCards .card-imgs {
    width: 30px;
    height: 30px;
  }
  .staff-card-head .icon {
    font-size: 15px;
    height: 15px;
  }
  .strippe-modal-wrap input[type="checkbox"] {
    width: 12px;
    height: 12px;
    top: 25px;
  }
  .stripeModalFooter .btn {
    padding: 11px 5px !important;
    font-size: 10px !important;
  }
}
.loginBody {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#loginForm {
  justify-content: center;
}

#loginForm .loginCard {
  margin-top: 50px;
}

#loginForm .authCard {
  margin-top: 50px;
}

#loginForm input {
  height: 32px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.signupBody {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.signupBody input.error {
  margin-bottom: 8px !important;
}

.signupBody label {
  display: none;
}

.signupBody label.error {
  margin-top: 8px !important;
  display: inline-block;
}

.loginBody .logo {
  width: 250px;
}

.selected {
  background-color: #800520 !important;
}

.signupBody .logo {
  width: 300px;
  margin-bottom: 50px;
}

.loginCard {
  width: 400px;
  padding: 20px 30px;
}

.authCard {
  width: 600px;
  /* height: 392px; */
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#authCodeTextField.MuiOutlinedInput-input {
  text-align: center;
  font-weight: 600;
  letter-spacing: 8px;
  max-width: 300px;
  font-size: 42px;
  padding: 12.5px;
  color: #800520 !important;
}

.signupBody .loginCard {
  width: 65%;
  padding: 20px 15px;
}

.nav-link {
  padding: 0;
}

.container {
  padding: 0;
}

.header {
  padding: 0;
}

.header-large {
  padding-top: 30px;
}

.header #welcomeMessage {
  margin: 0;
  line-height: 70px;
}

.header-large #welcomeMessage {
  margin-bottom: 30px;
  line-height: 42px;
}

.header ul li {
  color: black;
}

.primaryNavWithIcon {
  padding: 16px;
}

.primaryNavWithIcon > .icon {
  font-size: 32px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
}

.primaryNavWithIcon .text {
  margin-top: 20px;
}

.statsRow.row {
  justify-content: center;
}

.statsRow button {
  padding-right: 0px;
}

.stats {
  min-width: 220px;
  margin-bottom: 28px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
}

.stats:hover {
  border-color: lightgrey;
  cursor: pointer;
}

.stats .subtitle {
  margin-top: 8px;
  margin-bottom: 24px;
}

.stats .value {
  margin-left: 10px;
  font-size: 32px;
  font-weight: bold;
}

.stats .details {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statsRowTwo .card {
  height: 168.53px;
}

.statsRowTwo .value {
  font-size: 18px;
  color: #800520;
}

.hoverScaleCard:hover {
  box-shadow: 0 2px 4px 2px #eee;
}

.workoutDesigner {
  display: flex;
  align-items: center;
  height: calc(100vh - 80px);
  justify-content: center;
}

.workoutDesigner .card {
  padding: 20px;
}

.workoutDesigner .card-title {
  color: #800520 !important;
}

.workoutDesigner .card-title.title {
  margin: 0;
  color: black !important;
}

.fullContainer {
  height: 100%;
}

.designerList {
  min-height: 80vh;
}

.cardTitleLarge {
  font-size: 1.5rem;
}

.exerciseCard {
  margin: auto;
  margin-top: 5px;
  padding: 0 !important;
  transition: all 0.25s ease-out;
}

.exerciseCard .card-title {
  margin: 0px 0px 0 16px;
  font-size: 0.7rem;
}

.exerciseCard .details {
  padding-right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 160px;
}

.exerciseCard .card-title .exerciseName {
  padding-right: 0;
  padding-left: 8px;
  max-width: 230px;
  min-height: 32px;
  font-size: 10px;
  display: flex;
  overflow: hidden;
  max-height: 14px;
  color: black !important;
  text-align: left;
}

.exerciseCard.small .card-title {
  margin: 0 8px;
}

.exerciseCard.small .card-title .exerciseName {
  padding-left: 14px;
  padding-right: 8px;
  line-height: 13px;
}

#exerciseName {
  margin-top: 1px;
  border-bottom: none !important;
  border: solid 1px #c8c8c8 !important;
  padding: 0 !important;
  padding-left: 8px !important;
  border-radius: 4px !important;
}

#programName {
  flex: 1 1;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 1px;
  border-bottom: none !important;
  border: solid 1px #c8c8c8 !important;
  padding: 0 !important;
  padding-left: 8px !important;
  border-radius: 4px !important;
}

.exerciseCard.small .card-title .meta.exerciseCardLevel {
  font-size: 0.6rem;
}

.exerciseCard {
  text-align: left;
}

.exerciseCard .level {
  /* padding-right: 20px !important; */
  margin: 0;
  text-align: left;
  font-size: 0.6rem;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.workoutDaysEditor .exerciseCard .card-body {
  padding-bottom: 8px !important;
  padding-top: 0px;
  padding-left: 15px !important;
  padding-right: 0px !important;
}

.exerciseCard .card-body {
  padding-bottom: 8px !important;
  padding-top: 0px;
  padding-left: 15px !important;
  padding-right: 6px !important;
}

.exerciseCard .level > p {
  color: #800520;
  margin: 0;
}

.exerciseCard .card-title .meta {
  font-size: 0.6rem;
  line-height: 1rem;
}

.videoContainer {
  flex: 0.5 1;
}

.videoPlaceholder {
  background: black;
  width: 100%;
  flex: 1 1;
  height: 152px;
}

.exerciseInfo {
  display: flex;
  margin-top: 10px;
  margin-left: -8px;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  font-size: 0.75em;
}

.exerciseInfo p {
  font-weight: bold;
}

.exerciseInfo .meta {
  margin-right: 8px;
  min-width: 46px;
  font-weight: 300;
  display: inline-block;
}

.exerciseInfo .meta::after {
  content: ":";
}

.designerModal .exerciseCard {
  margin: 0 15px 15px;
}

.designerModal .exerciseCard .card-title {
  margin: 0 0 0 12px;
}

.exerciseCard .selectButtonContainer {
  font-style: normal;
  width: 24px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  background: #800520;
  color: white;
  border-radius: 50%;
  align-self: flex-start;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  /* Firefox all */
  /* IE 10 */
  user-select: none;
}

.exerciseCard .selectButtonContainer .selectButton {
  line-height: 28px;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  /* Firefox all */
  /* IE 10 */
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exerciseCard .selectButtonContainer:hover {
  background-color: #800520;
  box-shadow: 0 1px 2px 2px #eee;
}

.align-center {
  background-color: #800520;
  align-items: center;
  justify-content: center;
}

.video-react .video-react-big-play-button {
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  margin: auto;
}

.ReactVirtualized__Grid__innerScrollContainer {
  margin-right: auto;
}

.btn-outline-secondary {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #800520;
  background: transparent !important;
  border: 1px solid #800520;
}

.btn-outline-secondary:hover {
  border: 1px solid #800520;
}

.slide-pane .slide-pane__title {
  text-align: center;
  max-width: 85%;
}

/*chckout button*/
.checkoutbtn {
  text-decoration: none;
  color: white !important;
}

.slide-pane__close {
  margin-left: 8px !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.filtersSearch input {
  cursor: pointer;
  border-color: black !important;
}

.filterList {
  max-height: 400px;
  overflow: scroll;
  padding-left: 0;
}

.filtersModal {
  max-width: 80%;
}

.filtersModal .cancelButton {
  margin-right: 8px;
}

.filtersSearch {
  position: relative;
}

.clearFiltersButton {
  margin: 0;
  padding: 8px 14px;
  border-radius: 50%;
  background: transparent;
  color: #800520;
  font-weight: bold;
  font-size: 1.5em;
  z-index: 1;
  -webkit-transform: translateY(0) !important;
          transform: translateY(0) !important;
  box-shadow: none !important;
}

.clearFiltersButton:hover,
.clearFiltersButton:active,
.clearFiltersButton:focus,
.clearFiltersButton:active:focus {
  background: #fdf5f5 !important;
  -webkit-transform: translateY(0) !important;
          transform: translateY(0) !important;
  box-shadow: none !important;
  color: #800520 !important;
}

.listMessage {
  text-align: center;
  position: absolute !important;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
  left: 50%;
}

.dragDropRow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px 0 0;
  background: white;
  border-bottom: 1px solid #efefef;
}

.dragHandle {
  position: relative;
  display: block;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
}

.dragging {
  opacity: 0.7;
}

.workoutFinalView {
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.workoutFinalView .dragDropList {
  flex: 1 1;
  overflow: hidden;
}

.exerciseEditCard {
  color: black !important;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.exerciseEditCard .icon {
  color: rgba(0, 0, 0, 0.7);
}

.exerciseEditCard .videoContainer {
  display: flex;
  padding: 0 16px;
  justify-content: center;
  height: 70px;
  width: 150px;
  align-items: center;
}

.exerciseEditCard .title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 10px !important;
}

.exerciseEditCard .rowInput {
  margin: 0;
  max-width: 40px;
  padding: 0 !important;
  text-align: center;
  background-image: initial !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0 !important;
}

.exerciseEditCard .rowForm {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.exerciseEditCard .rowForm label {
  font-size: 0.65em;
  margin: 0;
}

.exerciseEditCard .indexLabel {
  padding-left: 45px;
}

.react-autosuggest__suggestions-list {
  padding: 0;
  overflow: scroll;
  position: absolute;
  background: white !important;
  max-height: 200px;
  width: 100%;
  z-index: 100;
  margin-top: 1px;
  box-shadow: 0 1px 3px 0px #00000052;
}

.suggestion {
  color: black;
  padding: 4px;
  padding-left: 8px;
  font-size: 11px;
  border-bottom: 1px solid #eee;
}

.suggestion:hover {
  background-color: #eee;
}

.fullWidth {
  width: 100%;
}

.bgTest {
  background-color: red;
}

.staffEditorRow {
  width: 100%;
  padding: 4px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.staffListHeaderContainer {
  display: flex;
  padding: 15px 0px;
  border-bottom: 1px solid lightgray;
  color: black;
}

.useTemplatesButton {
  margin-right: 20px !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  font-size: 14px !important;
  text-transform: none !important;
  font-weight: 300 !important;
}

#useTemplatesFormTypeSelect {
  width: 200px;
}

.adminListItem {
  padding-bottom: 4px !important;
}

.staffListHeaderItem {
  font-weight: 700 !important;
}

#staffListContainer {
  flex: 1 1;
}

#staffListContainer .ReactVirtualized__Grid {
  overflow-x: hidden !important;
}

.staffListActionButtonsContainer {
  display: flex;
  justify-content: center;
}

.staffMemberCardRow {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 80px;
  border-bottom: 1px solid lightgray;
  margin: 0;
}

.staffMemberDesignation {
  position: relative !important;
  bottom: 3px !important;
}

.staffMemberCardRow:hover {
  background-color: #f8f8f8;
}

.staffListCellEntry {
  flex: 0.225 1 !important;
  text-transform: none !important;
}

.staffListNameHeader {
  flex: 0.18 1 !important;
}

.staffMemberCardName {
  flex: 0.18 1 !important;
}

.staffListEmailHeader {
  flex: 0.27 1 !important;
}

.staffListActionsHeader {
  margin-right: 10px !important;
}

.staffMemberCardEmail {
  flex: 0.27 1 !important;
}

.staffMemberListTableContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 153px);
  margin: 10px 15px 20px 15px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
}

.staffMemberListTable {
  margin: 15px;
  max-height: 100% !important;
  overflow-y: scroll !important;
}

#staffListContainer {
  overflow-x: hidden;
}

.staffMemberAvatar {
  margin: 0 auto;
}

.roleDesignationTableCell {
  padding-bottom: 12px;
  position: relative;
  bottom: -16px;
}

.customPricingToggle .Mui-selected {
  background-color: #802520 !important;
  color: white !important;
}

.customPricingToggle .Mui-selected h6 {
  color: inherit;
}

.clientEditor {
  display: flex;
  align-items: center;
  height: calc(100vh - 80px);
  flex-direction: column;
}

.enterpriseEditor {
  display: flex;
  align-items: center;
  height: calc(100vh - 80px);
  flex-direction: column;
}

.enterpriseHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  left: 8px;
}

.personImageContainer {
  height: 70px;
  width: 70px;
  border-radius: 50px;
}

.clientCardAvatar {
  border: solid 1px lightgrey;
}

.mainImage {
  object-fit: cover;
  height: 100px;
  border-radius: 50px;
}

.fullWHListContainer {
  height: calc(100vh - 140px);
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: lightgray;
}

.programList {
  height: calc(100vh - 80px);
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: lightgray;
}

.editorPanelContainer {
  height: calc(100vh - 180px);
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: lightgray;
}

.clientCardHeading {
  height: 40px;
  margin: 0;
}

.enterpriseCardHeading {
  height: 40px;
  margin: 0;
}

.incFontWeight {
  font-weight: 500;
}

.clientEditor h3.card-title {
  margin-bottom: 0;
}

.enterpriseEditor h3.card-title {
  margin-bottom: 0;
}

.listContainer {
  height: 100%;
  border-right: 1px solid lightgray;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0;
}

.programListContainer {
  height: 100%;
  border-right: 1px solid lightgray;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
}

.centerAlignJustify {
  display: flex;
  align-items: center;
  justify-content: center;
}

.decFontSize {
  font-size: 0.8rem;
  overflow: hidden;
}

.formSequenceCard {
  background-color: transparent;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid lightgray;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formSequenceCardLeft {
  display: flex;
  align-items: center;
}

.formSequenceCardRight {
  margin-right: 4px;
}

.formSequenceCard:hover {
  background-color: #f8f8f8;
}

.formSequenceCardColumn {
  margin-left: 24px !important;
}

.clientSMCard {
  background-color: transparent;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  margin: 0;
}

.clientSMCard:hover {
  background-color: #f8f8f8;
}

.clientSMImgContainer {
  height: 40px;
  width: 40px;
  border-radius: 50px;
}

.clientDetailContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 16px;
}

.comingSoonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.enterpriseDetailContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding: 16px;
}

.programDetailContainer {
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

.clientDetailContainer .card {
  display: inline-flex;
}

.programDetailContainer .card {
  display: inline-block;
}

.programDetailContainer .card {
  display: inline-block;
}

.programDetailContainer .card {
  display: inline-block;
}

.clientDetailContainer .react-autosuggest__suggestions-list {
  max-height: 150px;
}

.programDetailContainer .react-autosuggest__suggestions-list {
  max-height: 150px;
}

.clientDetailCard {
  width: 80%;
  align-items: flex-start;
  margin: 0;
  margin-top: 40px;
}

.clientDetailCard .card-body {
  padding: 0;
  width: 100%;
}

.clientDetailCard #topLayout {
  width: 100%;
  padding: 16px;
  background-color: #800520;
  display: flex;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  align-items: center;
  justify-content: space-around;
}

.clientDetailCard #topLayout input {
  border-color: white;
  color: white;
}

.clientDetailCard #topLayout input::-webkit-input-placeholder {
  color: white;
}

.clientDetailCard #topLayout input::placeholder {
  color: white;
}

.clientSMImg {
  object-fit: cover;
  height: 40px;
  border-radius: 50px;
}

.clientExerciseCard {
  width: 80%;
  margin-top: 10px;
}

.exercisesTitle {
  padding: 10px;
  font-size: 1rem;
}

.fullContainer {
  height: 100%;
}

.emailMultiline {
  padding: 0px;
  display: inline;
  word-wrap: break-word;
}

.decPadding {
  padding: 8px;
}

.topBottomSpace {
  margin-top: 50px;
  margin-bottom: 70px;
}

.dayRow {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 0.2px solid lightgrey;
}

.dayRow:hover {
  background-color: #f8f8f8;
  cursor: pointer;
}

.selectedDayRow {
  background-color: #e0e0e0;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 0.2px solid lightgrey;
  cursor: pointer;
}

.dayText {
  font-size: 1.43rem;
}

.displayFlex {
  display: flex;
}

.workoutList {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.workoutContainer {
  width: 109%;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 80%;
}

.workoutListHeader {
  font-size: 0.9rem;
  justify-content: space-between;
  margin-bottom: 10px;
}

.workoutsModal {
  max-width: 30%;
}

.workoutListCard {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 109%;
  border-bottom: 0.2px solid lightgrey;
  cursor: pointer;
}

.workoutListCard:hover,
.workoutListCard.selected {
  background-color: #eee !important;
}

.editIconContainer {
  right: 10px;
  top: 10px;
  position: absolute;
  padding: 3px 6px 3px 6px;
  cursor: pointer;
}

.editIconContainerBlog {
  opacity: 0;
  position: absolute;
  padding: 40px 0px 8px 100%;
  cursor: pointer;
}

.editIconContainerBlog:hover,
.editIconContainerBlog.selected {
  opacity: 1;
  background-color: #800520 !important;
}

.editIconContainerBlog .editIcon {
  position: absolute;
  left: 50%;
  top: 12px;
  color: #fff;
}

.editIcon {
  width: 15px;
  height: 15px;
}

.forgotPasswordButton {
  color: grey;
  font-size: 0.7rem;
  padding: 3px;
  margin-bottom: 5px;
  cursor: pointer;
}

.forgotPasswordButton:hover {
  color: darkgrey;
}

.ReactModalPortal {
  position: absolute;
  z-index: 3;
}

.exerciseEditor {
  overflow: hidden;
}

#exerciseEditor {
  height: calc(100vh - 130px);
}

.exerciseEditorModal .form-group .innerSpan,
#feedbackForm.MISSING_EXERCISE .form-group .innerSpan {
  position: absolute;
  top: 5px;
  right: 8px;
  margin: 0;
  color: #5a5a5a;
}

#errorsEditorModal .form-group .innerSpan {
  position: absolute;
  top: 5px;
  right: 8px;
  margin: 0;
  color: #5a5a5a;
}

.stats button {
  color: #800520 !important;
  font-size: 12px !important;
}

.stats button:hover {
  background: #fdf5f5;
  color: #800520 !important;
}

.stats .action {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

.stats .nav-link {
  display: flex;
  align-items: center;
}

.nav-link-invert {
  background-color: #800520 !important;
  color: white !important;
}

.nav-link-invert:hover {
  background-color: white !important;
  color: #800520 !important;
}

.assign-all-text {
  font-size: 0.7rem;
  font-weight: 500;
  color: #800520;
}

.assign-all-text:hover {
  cursor: pointer;
}

.workout-list-button {
  color: #800520;
}

.workout-list-button:hover {
  color: grey;
}

.modal-title {
  color: #222a42;
  width: 100%;
}

#errorsModal {
  max-height: 95vh;
  max-width: 80vw;
  overflow-y: scroll;
}

.exerciseEditorModal .form-group input.innerSpanInput,
#feedbackForm.MISSING_EXERCISE .form-group input.innerSpanInput {
  padding-right: 50px !important;
}

#errorsEditorModal .form-group input.innerSpanInput {
  padding-right: 50px !important;
}

.editorPanelList {
  padding: 0px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
}

.editorPanelList .card {
  margin: 16px;
  min-width: 150px;
  min-height: 100px;
  width: initial;
  cursor: pointer;
}

.editorPanelList .card-body {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editorPanelList .editIconContainer {
  opacity: 0;
  will-change: opacity;
  transition: all 0.2s ease;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  background: #800520;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editorPanelList .editIconContainer .editIcon {
  position: relative;
  left: -5px;
  top: -5px;
  color: white;
}

.editorPanelList .card:hover .editIconContainer {
  opacity: 1;
}

.daterangepicker .ranges li {
  color: black;
}

.daterangepicker .ranges li.active,
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background: #800520 !important;
  color: white;
}

.daterangepicker td.in-range {
  background: rgba(189, 0, 0, 0.1);
}

.datePicker {
  width: 170px;
  margin-left: 12px;
  font-size: small;
  align-items: center;
  justify-content: center;
  text-align: left;
  align-self: center;
  padding-left: 42px !important;
  padding-right: 0px !important;
}

.datePicker:hover {
  cursor: pointer;
}

.workoutDayArrow {
  margin-left: -30px;
}

.topClientList {
  margin-top: 6px;
}

.modalLoader {
  display: flex;
  justify-content: center;
}

.modalLoader .modal-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.modalLoaderContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 320px) and (max-width: 400px) {
  /* .datePicker {
    margin-left: 0;
    margin-right: 20px;
  } */
}

.dateLabel {
  display: inline-block;
  margin-bottom: 0 !important;
}

.workoutDaysHeader {
  width: 98%;
}

.selectedCard {
  background-color: #e0e0e0;
  width: 100%;
  height: 90px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
  margin: 0;
}

.rightExerciseCardsContainer {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 100%;
}

.dayCardIcon {
  object-fit: cover;
  height: 20px;
}

.dayCardIcon:hover {
  -webkit-filter: drop-shadow(5px 5px 5px lightgrey);
  filter: drop-shadow(5px 5px 5px lightgrey);
}

.deleteIcon {
  object-fit: cover;
  height: 20px;
  margin-top: 12px;
  margin-right: 10px;
}

.deleteIcon:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(5px 5px 5px lightgrey);
  filter: drop-shadow(5px 5px 5px lightgrey);
}

.collapseContainer {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.exercisesRowScroll {
  width: 90%;
  height: 442px;
  justify-content: flex-start;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-left: 40px;
  flex-wrap: nowrap;
}

.sectionedExercisesRowScroll {
  width: 90%;
  margin-top: 10px;
  justify-content: flex-start;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-left: 40px;
  flex-wrap: nowrap;
}

.exercisesRowScroll .exerciseCard {
  min-width: 250px;
}

.lightText {
  color: grey;
  font-size: 0.6rem;
}

.selectedExercisesContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  right: 10px;
  top: 2px;
  flex: 1 1;
}

.fortisFormaDropDownSelect__menu {
  min-width: 200px;
  z-index: 9999999 !important;
  overflow: visible;
}

.fortisFormaDropDownSelect__placeholder {
  font-size: 0.8em;
}

.fortisFormaDropDownSelect__input input {
  border: 0 !important;
  padding-left: 0 !important;
  font-size: 0.8em;
}

.btn.btn-link:hover,
.btn.btn-link:focus {
  color: #800520 !important;
  text-decoration: underline !important;
}

.navbar {
  padding-bottom: 15px;
}

.navbar-collapse {
  padding-top: 0 !important;
  margin-right: 0;
  width: 100%;
  margin-left: 0;
  padding-left: 82px;
}

.nav-link:hover,
.nav-link:active,
.nav-link:focus,
.navbar-brand:hover,
.navbar-brand:focus {
  box-shadow: initial !important;
  background: #fdf5f5 !important;
  color: #800520 !important;
}

#dashboard .nav-link:hover,
#dashboard .nav-link:active,
#dashboard .nav-link:focus {
  background: #fdf5f5;
}

.drawer .logo {
  background-image: url("/assets/img/logo.png");
  background-repeat: no-repeat;
  text-align: center;
  background-size: contain;
  margin: 10px 0 5px 0px;
  background-position: center;
  height: 75px;
}

.accountOptions span {
  font-size: 11px;
}

.accountOptions .icon {
  font-size: 20px;
}

.nav-link.active {
  box-shadow: initial !important;
  border-bottom: 1px solid #800520 !important;
  background: transparent !important;
  color: #800520 !important;
  border-radius: 0 !important;
}

.dropdown-style .dropdown-toggle .btn .btn-secondary {
  background-color: transparent !important;
}

#exerciseFiltersContainer {
  flex: 1 1;
  position: relative;
}

#filterText,
#adminFilterText {
  opacity: 0.7;
  font-size: 0.8em;
  position: absolute;
  z-index: 10000;
  top: -15px;
  left: 8px;
}

#adminFilterText {
  top: -12px;
  left: 24px;
  z-index: 1000;
}

#exerciseFilters .form-group,
#exerciseFilters .input-group {
  margin-bottom: 0;
}

#deisgnerTopRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 8px;
  flex-direction: column;
}

#workoutDesignerDetails {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

.exerciseDetailsInputs {
  flex: 0.35 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#homeButtonContainer {
  padding: 0;
  margin-left: 5px;
}

#navigationContainer {
  padding: 0;
  margin-left: -10px;
}

.navbar.bg-dark {
  background: white !important;
}

.navbar .nav-item {
  color: #800520 !important;
}

.screenNavigationHelperButton {
  display: none;
  background: #800520;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: fixed;
  bottom: 12px;
  margin: auto;
  right: 12px;
}

.screenNavigationHelperButton .icon {
  color: white;
}

.exerciseEditCardDetails {
  flex: 0.45 1;
  align-items: center;
  align-self: flex-end;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.editorPanelTopRow {
  display: flex;
  align-items: flex-start;
  flex: 1 1;
  justify-content: flex-end;
  padding-bottom: 12px;
}

.filtersCollapsableButtonContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  padding-right: 15px;
  padding-left: 5px;
}

.exerciseFilterButtonsContainer {
  width: 110%;
  align-items: center;
  justify-content: center;
}

.badge {
  margin-top: -2px;
  margin-left: -6px;
  display: flex;
  align-self: flex-end;
  height: 20px;
  font-size: 0.5rem;
  width: 55px;
  background-color: #800520;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 800;
  right: 2px;
}

.rightMargin {
  margin-right: 5px;
}

.leftPaddingZero {
  padding-left: 0px;
}

.toggleTooltip {
  color: black;
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.bulkUploadButtonContainer {
  position: relative;
  margin-right: 8;
  margin-left: -12;
}

.exerciseSearchInput input {
  padding-right: 35px !important;
}

.exerciseSearchInput {
  margin-bottom: 0 !important;
}

.exerciseDeleteIconContainer {
  width: 100%;
}

.exerciseDeleteIcon {
  object-fit: cover;
  height: 40px;
}

.exerciseDeleteIcon:hover {
  cursor: pointer;
  -webkit-filter: drop-shadow(5px 5px 5px lightgrey);
  filter: drop-shadow(5px 5px 5px lightgrey);
}

.admin-component-card {
  margin: 0 !important;
  border-radius: 0 !important;
  min-height: 50px !important;
  height: 50px !important;
  width: 100% !important;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: none !important;
  border-bottom: solid 1px grey !important;
}

.admin-element-body {
  height: 10px !important;
}

.replayViewContainer {
  position: absolute;
  background-color: white;
  margin-left: 0px !important;
  margin-right: 0px;
  -webkit-filter: blur(180px);
          filter: blur(180px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.descriptionRow {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  display: flex;
}

.modal-open .modal {
  overflow: hidden !important;
}

.filterPadding {
  padding-right: 5px;
  padding-left: 5px;
}

.Select-value-label {
  white-space: pre-wrap;
}

.Select-option {
  white-space: pre-line;
}

.filterNamePadding {
  padding-right: 7px !important;
}

.loadingMessagesProgressBar {
  height: 14px;
  margin-top: 16px;
}

.progress-bar {
  background-color: #8f505d;
}

.exerciseEditorModal,
#feedbackForm.MISSING_EXERCISE {
  pointer-events: all;
}

.changePasswordText:hover {
  text-decoration: underline;
  cursor: pointer;
}

.crossIcon {
  position: absolute;
  top: -37px;
  right: 10px;
  font-size: 1rem;
}

.crossIcon:hover {
  cursor: pointer;
}

.approvalButtonAccept:hover {
  background-color: #a9d26a;
}

.approvalButtonReject:hover {
  background-color: #f0706a;
}

.programListHeader {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
}

.programListPlaceholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.programListContent {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.programListItem {
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px darkgrey;
}

.programListItem:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.selectedProgramListItem {
  width: 100%;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px darkgrey;
  color: white;
  background-color: #800520;
}

.selectedProgramListItem:hover {
  cursor: pointer;
}

.programDetailContent {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: calc(100% - 60px);
}

.programDetailDayStrip {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px darkgray;
}

.programDetailClientContainer {
  position: relative;
  flex: 0.4 1;
  border-right: solid 1px darkgray;
}

.programDetailWorkoutContainer {
  flex: 0.6 1;
}

.programDetailHeader {
  width: 100%;
  padding: 3px 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
}

.dayItem {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
}

.dayItemSelected {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #800520;
  color: white;
  font-size: 0.9rem;
  font-weight: 600;
}

.dayItem:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.disabledDayItem {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
  opacity: 0.3;
}

.programDetailClientList {
  flex: 1 1;
  height: 92%;
  padding-top: 7px;
  overflow-y: scroll;
}

.programDetailWorkoutList {
  height: calc(100% - 35px);
}

.clientListPlaceholder {
  flex: 1 1;
  height: 100%;
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  color: grey;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.workoutListPlaceholder {
  flex: 1 1;
  height: 100%;
  text-align: center;
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  color: grey;
  align-items: center;
  justify-content: center;
}

.programClientListItem {
  font-size: 0.74rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 7px 7px 10px;
}

.programClientListItemText {
  flex: 0.9 1;
}

.programClientListItemCheckboxContainer {
  flex: 0.1 1;
  display: flex;
  justify-content: flex-end;
}

.addMoreContainer {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: white;
}

.addMoreContainer:hover {
  opacity: 0.7;
  cursor: pointer;
}

.programDetailWorkoutList {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: center;
}

.programWorkoutListItem {
  display: flex;
  justify-content: center;
}

.assignButtonContainer {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.assignerModal {
  width: 30vw;
}

.assignerModal .modal-content .modal-footer {
  justify-content: space-between;
}

.primaryButton {
  border: solid 1px #800520 !important;
}

.secondaryButton {
  background-image: linear-gradient(
    to bottom left,
    #fff,
    #fff,
    #fff
  ) !important;
  background-color: white !important;
  color: #800520;
  border: solid 1px #800520;
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -16px;
}

#workoutTypeSelector {
  width: 50%;
  position: relative;
  display: flex;
  align-items: flex-start;
}

#workoutDaySelect {
  min-width: 300px;
  margin-right: 16px;
}

#searchWorkouts {
  min-width: 250px;
  position: relative;
}

#searchWorkouts {
  min-width: 200px;
  margin-right: 8px;
}

#workoutTypeSelector .spinnerContainer {
  top: 0;
}

#pendingClient {
  padding: 11px 16px !important;
}

.assignedWorkoutText {
  -webkit-transform: scale(1);
          transform: scale(1);
  text-align: center;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.workoutChanged {
  color: white !important;
  font-weight: 800;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  border-radius: 5px;
  background-color: #800520;
  transition: -webkit-transform 0.1s ease-in;
  transition: transform 0.1s ease-in;
  transition: transform 0.1s ease-in, -webkit-transform 0.1s ease-in;
}

.requestModalContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#drawerProfileButton {
  display: none;
}

#enterpriseLogo {
  max-height: 40px;
  margin-right: 10px;
}

#appbarProfileButton {
  display: inline-block;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.waitingPageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.enterpriseCardContainer {
  height: 100%;
  width: 100%;
}

.enterpriseDetailsCard {
  min-width: 100%;
}

.enterpriseDetailsField {
  width: 100% !important;
  margin-bottom: 20px !important;
}

#waitingTitle {
  margin-bottom: 8px;
}

#waitingAction {
  margin-top: 16px;
}

/* Upto Ipad */
@media only screen and (max-width: 992px) {
  #root {
    height: initial;
    min-height: 100vh;
  }

  #drawerProfileButton {
    display: inline-block;
  }

  #appbarProfileButton {
    display: none;
  }

  .workoutContainer {
    max-height: 46vh;
  }

  .pageContainer {
    height: auto !important;
    padding: 44px 12px !important;
  }

  #mobileStyleCreatePractitioner {
    margin-top: 12px;
    flex-direction: column;
  }

  .navbar-collapse {
    z-index: 100000000000;
  }

  .form-check {
    margin-bottom: 14px;
  }

  .termsAndCaptchaContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column !important;
    min-height: 88px;
  }

  .exerciseDetailsCard {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }

  .fullWidth {
    max-height: 560px;
  }

  .fullContainer {
    min-height: 500px;
  }

  .editorPanelContainer {
    height: initial;
    margin-left: -15px;
  }

  .editorPanelContainer .listContainer {
    max-height: 600px;
  }

  .loginBody,
  .signupBody,
  .profileBody {
    height: 80vh;
    margin: auto;
  }

  .header ul {
    padding: 0;
  }

  .navbar {
    padding-bottom: 12px;
  }

  .navbar-collapse {
    height: 100vh !important;
    top: 40px !important;
    padding-left: 82px !important;
  }

  .navbar .navbar-collapse .navbar-nav li {
    padding: 20px 0 !important;
  }

  .header .nav-item {
    text-align: center;
    width: 100%;
    padding: 0 !important;
  }

  .header .nav-item a {
    padding: 8px 0 !important;
    margin: 0 !important;
  }

  #homeButtonContainer {
    margin-left: 0 !important;
  }

  #navigationContainer {
    margin-left: 0 !important;
    margin-bottom: 16px;
  }

  #deisgnerTopRow #exerciseFiltersContainer {
    min-width: 65%;
  }

  #deisgnerTopRow #exerciseFiltersContainer .form-group {
    margin-bottom: 8px;
  }

  #workoutDesignerDetails {
    flex-direction: column;
    min-width: 65%;
    margin: 8px 0;
  }

  .screenNavigationHelperButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .workoutDesigner {
    height: initial;
  }

  .clientDetailContainer {
    min-height: 300px;
  }

  .programDetailContainer {
    min-height: 300px;
  }

  .clientEditor .fullWHListContainer {
    margin-left: -15px;
  }

  .enterpriseEditor .fullWHListContainer {
    margin-left: -15px;
  }

  .admin-component-card {
    width: 100% !important;
    box-shadow: none !important;
    border: solid 1px grey !important;
  }

  .admin-element-body {
    height: 30px !important;
  }

  .margin-zero {
    margin: 0 !important;
  }

  .padding-zero {
    padding: 0 !important;
  }

  .editorPanelTopRow {
    flex-direction: column;
  }

  .editorPanelTopRow .col-sm-12 {
    margin-bottom: 8px;
  }

  .editorPanelTopRow #exerciseFilters {
    margin-bottom: 16px;
  }

  .react-toggle-thumb {
    top: 1px !important;
  }

  #exerciseToggle {
    margin-left: 20px;
  }

  .listMessage {
    top: 80%;
  }

  .exerciseEditorModal,
  #feedbackForm.MISSING_EXERCISE {
    max-height: 100vh !important;
    max-width: 100vw !important;
    overflow-y: scroll;
    overflow-x: hidden !important;
  }

  .enterpriseInviteCreateAccountContainer {
    margin: 32px 12px !important;
  }
}

#clientsFilterContainer .form-group {
  margin-bottom: 0;
  width: 100%;
}

.summaryDateContainer {
  height: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-bottom: solid 0.5px grey;
}

.summaryDateContainer:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.summaryDateContainerSelected {
  height: 40px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  border-bottom: solid 0.5px grey;
}

.summaryContentHeading,
.extrasummaryContentHeading,
.AssignedsummaryContentHeading {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-weight: 600;
}

.extrasummaryContentHeading {
  display: flex;
}

.AssignedsummaryContentHeading {
  margin-top: initial !important;
}

.summaryWorkoutColumn {
  justify-content: center;
  display: flex;
  padding: 0;
}

.loaderContainer {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boldText {
  font-weight: bold !important;
}

.rdw-editor-main {
  border: 1px solid #f1f1f1 !important;
  min-height: 150px;
  padding: 5px !important;
}

.rdw-editor-wrapper {
  margin-top: 16px;
}

/* Ipad specfic */
@media only screen and (min-width: 760px) and (max-width: 992px) {
  #CreatePractitionerButton {
    width: 100% !important;
  }

  #workoutSummaryName {
    font-size: 1em !important;
    margin-right: 10px !important;
  }

  .navbar-collapse {
    padding-left: 100px !important;
  }

  .navbar .navbar-collapse .navbar-nav li {
    padding: 8px 0 !important;
  }
}

/* After Ipad */
@media only screen and (min-width: 767px) {
  .navbar-brand {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .signupBody {
    height: 100vh;
    min-height: initial;
    margin-bottom: 0;
  }

  .profileBody {
    height: 80vh !important;
  }

  .navbar .nav-link {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .exerciseEditorModal,
  #feedbackForm.MISSING_EXERCISE {
    max-height: 100vh;
    max-width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  #errorsModal {
    max-width: 80vw;
  }

  #assignerTopRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.workoutDesignerListContainer {
  max-width: 34%;
}

/* Ipad rotate specific */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .dragDropRow {
    padding: 0 !important;
  }

  .exerciseDetailsInputs {
    flex: 0.38 1;
  }

  .editCardDetailButton {
    flex: 0.32 1 !important;
  }

  .workoutDesignerListContainer {
    max-width: 26%;
  }

  #mobileStyleLink {
    margin-left: 12px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #exerciseFiltersContainer {
    flex: 1 1;
  }

  .dragDropRow {
    padding: 16px 0;
  }

  .exerciseEditCardDetails {
    flex: 0.3 1;
  }

  .exerciseDetailsInputs .form-group {
    margin-bottom: 8px;
  }

  #exerciseFilters .col-sm-12 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

@media only screen and (min-width: 1000px) {
  #deisgnerTopRow {
    padding: 0 1em;
    flex-direction: row;
  }
}

@media only screen and (max-height: 500px) {
  .exerciseEditorModal,
  #feedbackForm.MISSING_EXERCISE {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.inputAddonIconButton {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  bottom: 1px;
  top: 1px;
  background: white;
  color: #b8b8b8;
  z-index: 3;
}

.inputAddonIconButton .icon.material-icons {
  font-size: 20px;
}

.filterQueryTextPadding {
  padding-right: 6px;
  padding-left: 0;
}

.react-toggle {
  touch-action: pan-x;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #800520;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #800520;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.versionText {
  font-size: 0.6em;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 8px;
}

.errorReportingButton {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 190px;
  height: 42px;
  margin: auto;
}

.blurVideo {
  -webkit-filter: blur(0.3em);
          filter: blur(0.3em);
  overflow: hidden;
}

.videoReplayButton {
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 52px;
  height: 52px;
  position: absolute;
  padding: 0;
  background: rgba(0, 0, 0, 0.45) !important;
}

.small .videoReplayButton {
  top: -40px;
  width: 42px;
  height: 42px;
}

.videoReplayButton i {
  font-size: 32px;
  font-weight: bold;
}

.small .videoReplayButton i {
  font-size: 28px;
}

.videoReplayButton:hover {
  background-color: transparent !important;
}

.genericFilters {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.textRightPadding {
  padding-right: 20px !important;
}

.emptyPlaceHolder {
  text-align: center;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.hidden,
[hidde] {
  display: none;
}

.pointer {
  cursor: pointer;
}

.approvalsLoader {
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorialButton {
  position: fixed;
  top: calc(40% - 175px);
  right: -3px;
  padding: 0px;
  margin: 0px;
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  cursor: pointer;
  background-color: #812520;
  color: white;
  padding: 10px 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transition: 0.15s all ease-out;
  z-index: 101;
}

.tutorialButton:hover {
  right: 0;
  box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.24);
}

.feedbackButton {
  position: fixed;
  top: calc(50% + 30px);
  right: -3px;
  padding: 0px;
  margin: 0px;
  transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  cursor: pointer;
  background-color: #812520;
  color: white;
  padding: 10px 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transition: 0.15s all ease-out;
  z-index: 101;
}

.feedbackButton:hover {
  right: 0;
  box-shadow: 0 0 35px 2px rgba(0, 0, 0, 0.24);
}

#feedbackForm.MISSING_EXERCISE {
  max-width: 50vw;
  top: 55%;
}

#feedbackForm.MISSING_EXERCISE .modal-body > .row {
  height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 80px;
}

#feedbackForm.MISSING_EXERCISE .modal-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
}

#feedbackForm.MISSING_EXERCISE .modal-body > .row::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

#feedbackForm.MISSING_EXERCISE .modal-body > .row::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
}

#feedbackForm .fileUploader {
  margin-top: 8px;
}

#feedbackForm .fileUploader .progress {
  margin-top: 12px;
  margin-bottom: 12px;
  background: transparent;
}

.clientDetailCard .actionButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 16px;
  width: 100%;
  flex-wrap: wrap;
}

@media (min-width: 992px) and (max-width: 1140px) {
  #clientDetailAssignCoach {
    width: 100%;
  }

  .actionButtonClientDetails {
    justify-content: space-evenly !important;
  }
}

​ .startWorkoutContainer {
  padding-top: 8px;
}

.actionButtonClientDetails {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

.messageButton {
  display: flex;
  justify-content: center;
}

.textareaField {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.messageButton:hover {
  background: #fdf5f5 !important;
  text-decoration: none !important;
}

.clientDetailCard .actionButtons .btn.btn-link:hover {
  background: #fdf5f5 !important;
  text-decoration: none !important;
}

.form-group .form-control,
.input-group .form-control {
  padding-bottom: 0px !important;
}

#deleteButtonHeader {
  display: inline-block !important;
  min-width: 45px;
}

#feedbackForm.MISSING_EXERCISE label.error {
  font-size: 11px !important;
}

.password-strength-label {
  position: relative;
  top: -10px;
  font-weight: 700;
  font-size: 0.75rem;
  min-height: 18px;
  display: inline-block;
}

.clientEditor .nav.nav-tabs {
  justify-content: space-around;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
  padding-bottom: 0;
}

.enterpriseEditor .nav.nav-tabs {
  justify-content: space-around;
  border-bottom: 1px solid #eee;
  margin-bottom: 4px;
  padding-bottom: 0;
}

.clientTabsLink {
  cursor: pointer;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  color: #812521 !important;
}

.clientTabsLink:hover {
  border-bottom: 1px solid #800520 !important;
}

.clientEditor .nav-tabs > .nav-item > .nav-link.clientTabsLink:hover {
  border-radius: 0;
  border-bottom: 1px solid !important;
}

.enterpriseEditor .nav-tabs > .nav-item > .nav-link.clientTabsLink:hover {
  border-radius: 0;
  border-bottom: 1px solid !important;
}

#clientEditorListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid lightgray;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
}

#enterpriseEditorListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid lightgray;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
}

#clientEditorListContainer .listContainer {
  border-right: 0 !important;
}

#enterpriseEditorListContainer .listContainer {
  border-right: 0 !important;
}

.clientSelectionCardCheck {
  height: 17px;
  width: 17px;
  background: white;
}

.clientSelectionCardCheck[type="checkbox"] :checked {
  background: #812521;
}

#clientSelectorModal {
  min-height: 480px;
  max-height: 480px;
  width: 500px;
  display: flex;
}

#clientSelectionList {
  min-height: 480px;
  overflow: hidden;
}

.clientSelectionCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  height: 50px;
  background-color: transparent;
}

.clientSelectionCard .decPadding {
  padding: 0 8px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  height: 50px;
}

.clientSelectionCard p {
  color: black !important;
  margin: 0;
  line-height: 16px;
}

.clientSelectionCard p:nth-child(1) {
  font-weight: bold;
}

#clientSelectorModal .ReactVirtualized__Grid {
  overflow-x: hidden !important;
}

.programTemplates .ReactVirtualized__Grid {
  overflow-x: hidden !important;
}

.clientSelectionCard .form-check-sign {
  display: inline-block;
}

.programTemplateActionIcon {
  padding: 0 8px;
}

.programName {
  padding-left: 16px;
}

.clientSelectorModalTabs {
  display: flex;
  justify-content: space-around;
}

.exerciseDetailsCard .details {
  max-width: 300px;
}

.dayRowList {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#workoutAssignerList {
  overflow-x: hidden;
  overflow-y: scroll !important;
  padding-top: 0;
}

#workoutAssignerTabs {
  position: -webkit-sticky;
  position: sticky;
  background: white;
  top: 0px;
  z-index: 1;
  width: 110%;
  display: flex;
  align-items: center;
  margin: 0 -15px;
  justify-content: center;
}

.levelChangeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #800520 !important;
  font-size: 0.7rem;
  position: relative;
  left: -8px;
  margin-top: 4px;
  padding: 0;
}

.levelChangerButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.videoContainer.small video {
  object-fit: cover;
}

.levelChangerButton:hover {
  background-color: #eee;
  opacity: 0.8;
}

.levelChangerButton[disabled] {
  pointer-events: none;
  opacity: 0.2;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.exerciseEditCard .levelChangeContainer {
  justify-content: flex-start;
}

.exerciseEditCard .levelChangerButton {
  margin: 0 8px;
}

.collapsedContainer {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  align-items: flex-end;
}

.navbar {
  z-index: initial !important;
}

.similarExPane {
  width: 30% !important;
}

.workoutSummaryExPane {
  width: 96% !important;
}

.loadMoreButton {
  min-height: 40px;
  margin: 12px 0;
  width: 225px;
  color: #800520;
  background: #eee;
}

.termsConditionButton {
  color: #800520;
  text-decoration: underline;
}

#termsModalHeader {
  padding-bottom: 20px;
}

#termsModalBody {
  max-height: 60vh;
  padding-top: 0 !important;
  overflow-y: scroll;
}

.termsConditionButton:hover {
  opacity: 0.5;
}

.termsAndCaptchaContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  min-height: 78px;
}

.btn {
  border-radius: 4px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.alert {
  position: absolute;
  top: 0;
  width: 90%;
}

.fullPageModal {
  height: 100%;
  max-width: 100% !important;
  display: flex;
}

.fullPageModal .modal-content {
  flex: 1 1;
}

.fullPageModal .modal-content .modal-body {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.nopadding {
  padding: 0;
}

.exerciseDesignerFormMainContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
}

#uploaderFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
}

.halfFlex {
  flex: 0.48 1;
}

.textFieldFullWidth {
  width: 100%;
}

.marginBottom {
  margin-bottom: 16px !important;
}

.twoFieldContainer {
  display: flex;
  flex-direction: row;
}

.marginLeft {
  margin-left: 8px !important;
}

.marginRight {
  margin-right: 8px !important;
}

#loggedWorkoutDropdown {
  width: 50%;
  margin-left: 16px;
}

.dropdownHeight .fortisFormaDropDownSelect__control {
  min-height: 52px;
}

.dividerFullwidth {
  margin-top: 8px !important;
  margin-bottom: 16px !important;
  width: 100%;
}

.flexWrapClass {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.fixedWidth {
  width: 260px;
}

.subHeading {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.textFieldFullWidth input {
  border-bottom: none !important;
}

.dropdownUnderline input {
  border-bottom: none !important;
}

.alternateNamesContainer {
  width: 100% !important;
}

.addUploadContainer {
  width: 320px;
  padding-top: 10px !important;
  padding-bottom: 10 !important;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  min-height: 198px;
  cursor: pointer;
}

.addUploadContainer:hover {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
}

.uploadIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#exerciseDesignerContainer {
  display: flex;
  justify-content: center;
}

.uploaderNote {
  min-width: 310px;
  display: flex;
  justify-content: flex-start;
}

.uploadImageContainer {
  width: 320px;
  height: 320px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  min-height: 194px;
  flex-direction: column;
  padding-bottom: 0;
  max-height: 140px;
}

#uploadImageText {
  margin-left: 8px;
}

.uploadImageContainer img {
  max-height: 80%;
  flex: 1 1;
  object-fit: contain;
}

.imageActions {
  position: absolute;
  justify-content: end;
  top: 10px;
  right: 12px;
  width: 100%;
}

.paddingBottom {
  padding-bottom: 6px;
}

#addExerciseModal {
  width: 84% !important;
  margin-right: 0 !important;
}

#addExerciseAction {
  width: 81%;
  display: flex;
  justify-content: flex-end;
}

.overlappingLabel {
  z-index: 1;
  left: 12px !important;
  top: -4px !important;
  background-color: white;
  pointer-events: none;
}

#levelDeciderContainer {
  display: flex;
}

#levelDeciderContent {
  min-width: 100px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.levelTabsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#addNewLevelButtonContainer {
  border-radius: 20px;
  background-color: #800520;
}

.levelTab {
  background-color: #eee !important;
  outline: none !important;
}

.levelTabUnsaved {
  background-color: #eee !important;
  opacity: 0.5 !important;
  outline: none !important;
}

.levelTabsContainer .MuiTabs-scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.levelTabsContainer .MuiTabs-scrollable {
  -ms-overflow-style: none;
}

#exerciseDesignerHeading {
  padding-top: 16px;
  width: 100%;
  margin-bottom: 16px;
}

#movementFormControl {
  flex: 0.8 1;
}

.uploadingContainer {
  display: flex;
  align-items: flex-end;
}

.smallIcon {
  font-size: 14px !important;
}

.levelTab .MuiButtonBase .MuiTab-wrapper {
  flex-direction: row-reverse !important;
}

#editAndMovementContainer {
  display: flex;
}

#levelAndEdit {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex: 0.2 1;
  justify-content: center;
  margin-left: 10px;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  padding-left: 12px;
}

#requestExerciseLink {
  margin-top: 10px;
}

.instructionMarginBotton {
  font-size: 12px;
  margin-bottom: 3px;
}

.fortisFormaDropDownSelect__single-value {
  font-size: 0.7rem;
}

.dropdownHeight .fortisFormaDropDownSelect__single-value,
.dropdownHeight .fortisFormaDropDownSelect__placeholder {
  font-size: 14px;
}

.clearFiltersButton {
  margin-top: 16px !important;
}

.dropdownAddLabel {
  font-weight: 800;
  color: #800520;
}

#searchExerciseFilter {
  border-bottom: none !important;
  border: solid 1px #c8c8c8 !important;
  padding: 0 !important;
  padding-left: 9px !important;
  margin-left: 15px !important;
  border-radius: 4px !important;
}

#exerciseDetailEditCard {
  border-bottom: solid 1px #e0e0e0;
}

.workoutSectionHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -2px;
  font-weight: 600;
  color: #800520;
}

.selectedWorkoutSection {
  background-color: rgba(128, 5, 32, 0.05);
}

.workoutSection {
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 12px;
  border: solid 1px #e0e0e0;
}

.unselectedWorkoutSection {
}

.sectionPlaceholder {
  width: 100%;
  text-align: center;
  padding: 36px 0px;
}

.addSectionButton {
  position: fixed !important;
  bottom: 24px;
  right: 24px;
}

.droppableSection {
  flex-grow: 1;
  min-height: 100px;
}

.addNewSection {
  height: 62px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eeeeee;
  border: solid 0.5px #e0e0e0;
  font-size: 0.8rem;
  border-radius: 5px;
}

.addNewSection:hover {
  background-color: #e0e0e0;
  font-weight: 500;
}

.setCounterAndDeleteContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewWorkoutSection {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.viewWorkoutSectionHeader {
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
}

.viewWorkoutSectionName {
  display: flex;
  font-size: 1.1rem;
  font-weight: 600;
}

.viewWorkoutPSectionName {
  display: flex;
  font-size: 1.1rem;
  font-weight: 600;
}

.viewSetsCounter {
  margin-left: 20px;
  margin-top: 1px;
  font-size: 1rem;
  font-weight: 600;
  color: #800520;
}

.sectionedWorkout {
  width: 100%;
}

.sectionedExerciseCard {
  display: flex;
  margin-bottom: 10px;
}

.sectionedVideoPlayer {
  display: flex;
  position: relative;
}

.workoutSessionSectionedExerciseConfig {
  margin-top: -2px;
}

.sectionedCardContent {
  flex: 1 1;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: space-around;
  overflow: hidden !important;
}

.sectionedExerciseNameLevel {
  display: flex;
  justify-content: space-between;
}

.sectionedExerciseName {
  font-size: 0.8rem;
}

.sectionedExercisePName {
  font-size: 0.8rem;
}

.sectionedExerciseLevel {
  background: #800520;
  display: flex;
  color: #fff;
  font-weight: 600;
  justify-content: center;
  padding-right: 14px;
  padding-left: 14px;
  border-radius: 2px;
  font-size: 0.6rem;
  align-items: center;
}

.configMainContainer {
  display: flex;
  margin-right: 10px;
}

.configImageKey {
  opacity: 0.6;
}

.configKey {
  font-size: 0.65rem;
  font-weight: 500;
}

.configValue {
  font-size: 0.65rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-left: 4px;
}

.assignerCancelButton {
  padding-right: 24px;
  padding-left: 24px;
  margin-left: 8px;
}

.floatingButton {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  bottom: 52px;
  right: 12px;
}

.editCardNote.form-control {
  height: 80px !important;
  padding: 6px 6px 0 6px !important;
  border: solid 1px lightgrey !important;
  border-radius: 4px;
}

@media (min-width: 1365px) {
  .workoutContainer {
    max-height: 80%;
  }
}

@media (min-width: 1919px) {
  .workoutContainer {
    max-height: 60vh;
  }
}

#forgotPassLoginOptions .MuiButton-root:nth-child(1) {
  margin-bottom: 12px;
}

#dragHandleAndNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin-left: 4px;
}

.posterImg {
  object-fit: contain;
}

.workoutLogCalendarCard {
  padding-left: 6px;
  color: #800520;
  font-weight: 500;
  padding: 2px 6px 2px 6px;
}

.workoutLogCalendarCard .heading {
  display: block;
  justify-content: space-between;
  align-items: center;
  white-space: normal;
}

.workoutLogCalendarCard .feedback {
  font-size: 10px;
}

.workoutLogCalendarCard .feedback > div {
  word-break: break-word;
  overflow: hidden;
  width: 100%;
  white-space: pre-wrap;
}

.workoutLogCalendarCard .feedbackTitle {
  font-style: italic;
}

.workoutLogCalendarCard:hover {
  cursor: pointer;
  background-color: #800520;
  color: #ffffff;
}

.workoutLogCalendarCard:hover #workoutFeedbackDot {
  background-color: #ffffff;
}

#summaryCalendarContainer {
  margin: 0 24px 24px 24px;
}

#workoutSummaryLoader {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  left: 45%;
}

#summaryLoadingText {
  margin-left: 8px;
}

.workoutFeedbackDot {
  background-color: #800520;
  height: 4px;
  width: 4px;
  border-radius: 4px;
}

.summaryExerciseFeedback {
  margin-top: 19px;
}

#feedbackNotLogged {
  margin: 10px;
  min-height: 58.5px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#feedbackLogged {
  margin: 10px;
  min-height: 58px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedbackContentHeading {
  margin-top: 19px;
  margin-bottom: 18px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

#summaryFeedbackContainer {
  position: relative;
  flex-direction: row;
  min-height: 58px;
  margin: 10px;
}

.displayColumn {
  display: flex;
  flex-direction: column;
}

.feedbackSectionHeader {
  font-weight: 600;
  font-size: 10px;
}

.feedbackLeftPortion {
  flex: 0.12 1;
}

.feedbackRightPortion {
  display: flex;
  flex-direction: column;
  flex: 0.7 1;
}

.feedbackMiddlePortion {
  flex: 0.18 1;
  margin: 0 8px;
}

.feedbackAns {
  color: #4d4d4d;
  font-size: 12px;
}

.feedbackEmoji {
  color: #9a9a00 !important;
  font-size: 14px !important;
  margin-left: 4px;
}

.noDescriptionText {
  color: darkgrey;
  font-size: 12px;
  font-style: italic;
}

.displayRow {
  display: flex;
  flex-direction: row;
}

.alignCenter {
  align-items: center;
}

.clientDetailCard .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.trainerProfilePicContainer {
  padding: 10px;
  display: flex;
}

.trainerProfilePic {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain;
}

.trainerProfilePic img {
  object-position: top;
  background-color: #e0e0e0;
}

.publicTrainerContent {
  width: 80%;
}

.publicContent {
  font-weight: 600;
  color: darkgrey;
}

.publicTopContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.publicBottomContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.certificationText {
  font-size: 12px;
  color: black;
}

.certificationLinkText {
  font-size: 12px;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.certificationLinkText:hover {
  opacity: 0.7;
}

.educationCertificationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verificationCheck {
  margin-right: 10px;
}

.programNameText {
  font-size: 12px;
  color: black;
}

.boldFont {
  font-weight: 600;
}

#completeProfileCard:hover .profileBadge .MuiBadge-badge {
  background-color: #800520 !important;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginBottom8 {
  margin-bottom: 8px !important;
}

.textFieldHalfWidth input {
  border-bottom: none !important;
}

.textFieldHalfWidth {
  min-width: 49% !important;
}

.mgTopAndBottom {
  margin: 16px 0 !important;
}

.mgTopAndBottom64 {
  margin: 64px 0 !important;
}

.mgTop32 {
  margin-top: 32px !important;
}

.justifyContentSpaceBetween {
  justify-content: space-between !important;
}

.programDropdownHeight .fortisFormaDropDownSelect__control {
  min-height: 56px;
}

.nameTextField input {
  border-bottom: none !important;
}

#programForm {
  margin-right: 32px;
  margin-bottom: 32px;
}

.fortisformaPDF {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  margin: 8px 0;
  min-height: 150px;
}

.loadingCenter {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 48px;
  width: 48px;
  margin: auto;
}

.aboutTextArea {
  width: 100%;
}

.requiredText {
  font-size: 10px;
  color: grey;
}

#yearPickerBio .MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.pd-32 {
  padding: 32px;
}

.educationList li {
  color: initial;
}

.customHelperText {
  margin-left: 8px !important;
  margin-right: 8px !important;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.6666666666666666rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.66;
}

.signupCard.card form label + .form-control {
  margin-bottom: 0 !important;
}

.signupCard .input-group,
.signupCard .form-group {
  min-height: 38px;
}

.opacityZero {
  opacity: 0;
}

#closeShareModalIcon {
  position: absolute;
  right: 0;
  z-index: 1;
}

.mgBottom32 {
  margin-bottom: 32px !important;
}

.textAlignCenter {
  text-align: center !important;
}

.mgBottom16 {
  margin-bottom: 16px !important;
}

#getLinkIcon.MuiIconButton-root {
  background-color: rgba(0, 0, 0, 0.08) !important;
  margin-right: 12px;
  cursor: default;
}

#shareLink {
  background-color: rgba(0, 0, 0, 0.08) !important;
  padding: 12px;
  border-radius: 8px !important;
  max-width: 450px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1;
}

.mgRight16 {
  margin-right: 16px !important;
}

.justifyContentCenter {
  justify-content: center !important;
}

.justifyContentFlexEnd {
  justify-content: flex-end !important;
}

#useAnywayButton.MuiButtonBase-root {
  font-size: 14px;
  margin-top: 16px;
}

.movementChangeLeft {
  width: 100%;
}

.movementChangeContainer {
  max-height: 50vh;
  overflow-y: scroll;
}

.movementChangeItem {
  padding: 6px 12px;
  border: solid 1px lightgrey;
  border-radius: 4px;
  margin-bottom: 4px;
  cursor: pointer;
}

.sMovementChangeItem {
  padding: 6px 12px;
  border: solid 1px #800520;
  border-radius: 4px;
  margin-bottom: 4px;
}

.workoutCardCollapsedHeight {
  max-height: 60px !important;
}

#enterpriseInviteLoadingContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointerEventsNone {
  pointer-events: none;
}

#landingAppBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
}

#arrowLogo {
  height: 40px;
  margin-right: 8px;
}

#textLogo {
  height: 26px;
}

.enterpriseInvitePageContainer {
  width: 100vw;
  height: 100vh;
  padding-top: 16px;
}

.enterpriseInviteCreateAccountContainer {
  max-width: 800px;
  margin-top: 32px;
  text-align: left;
  padding: 32px;
  margin: 32px;
}

.primaryColor {
  color: #800520;
}

.passworField .MuiFormControl-root {
  width: 100% !important;
}

#enterpriseName {
  margin-left: 8px !important;
  text-transform: initial !important;
}

.enterPriseInviteContainer {
  display: flex;
  justify-content: center;
}

.textColorGray {
  color: gray;
}

.enterpriseCardActions {
  width: 100%;
  text-align: right;
  margin-top: 20px !important;
}

.enterPriseInviteContainer .form-group {
  margin-bottom: 0px !important;
}

.enterPriseInviteContainer .password-strength-label {
  position: initial !important;
  min-height: 0px !important;
}

.notFoundImage {
  height: 200px;
}

.enterpriseComponentContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textTransformInitial {
  text-transform: initial;
}

.mgTop16 {
  margin-top: 16px !important;
}

.facilityInfoMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 80px);
  padding: 56px !important;
}

.facilityInfoCardContainer {
  max-width: 800px;
  text-align: left;
}

.facilityInfoTextField {
  max-width: 230px;
  margin: 16px 0 !important;
  width: 230px;
}

.facilityInfoTextField .react-autosuggest__suggestions-list {
  max-width: 230px !important;
  width: 230px !important;
}

.facilityInfoButtonContainer {
  justify-content: center;
  padding-top: 0 !important;
  padding-bottom: 32px !important;
}

.facilityInfoCardContentContainer {
  padding: 32px !important;
  padding-bottom: 0 !important;
}

.imageMainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoAndNameContainer {
  display: contents;
}

.logoImageContainer {
  width: 250px;
  height: 150px;
  object-fit: contain;
}

.facilityInfoHeaderText {
  margin-bottom: 32px !important;
}

.facilityInfoTextField .form-control {
  padding-bottom: 0 !important;
  color: #000 !important;
}

.facilityInfoTextFieldContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.enterpriseInviteNameContainer {
  display: flex;
  justify-content: space-between;
}

.imageProfileContainer {
  display: flex;
  justify-content: center;
}

/*-------Add Member Modal starts here----------*/
.addEditButtonContainers {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}

.closeIconButton {
  position: absolute !important;
  right: 0;
  top: 0;
}

.memberFieldsContainer {
  display: flex;
  flex-direction: column;
}

.firstRowFieldsContainer {
  display: flex;
  flex-direction: row;
}

.secondRowFieldsContainer {
  display: flex;
  flex-direction: row;
}

.useTemplatesDropdownContainer {
  width: 140px;
}

.memberInputFields {
  margin: 12px 0 !important;
}

.memberInputRegistrationField {
  margin: 12px 0 !important;
}

/* Chrome, Safari, Edge, Opera */
.memberInputRegistrationField input::-webkit-outer-spin-button,
.memberInputRegistrationField input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.memberInputRegistrationField input[type="number"] {
  -moz-appearance: textfield;
}

.addMemberDialogTitleContainer {
  display: flex;
  align-items: flex-start;
}

.leftMargin {
  margin-left: 12px !important;
}

.rightMargin {
  margin-right: 12px !important;
}

.memberAddDialogTitle {
  margin: 12px 0 !important;
  text-align: center;
}

.addMemberModalLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px;
}

.profileImageContainer label {
  display: inline-block;
}

.profileImageContainer {
  width: 100px;
  height: 100px;
}

.alignSelfCenter {
  align-self: center !important;
}

.memberInputFields .MuiInputBase-input {
  padding-left: 8px !important;
}

/*-------Add Member Modal ends here----------*/
/*----- form/document management starts here ------------ */

.documentEditorContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 16px 22px;
}

.documentEditor {
  border: solid 1px #d3d3d3;
  border-radius: 6px;
  width: 100%;
}

.useTemplatesContent {
  border: solid 1px #d3d3d3;
  border-radius: 6px;
  width: 100%;
  height: calc(100vh - 250px);
  overflow-y: scroll;
}

.TabsContainer {
  border-bottom: solid 1px #d3d3d3;
}

.headerAndButtonContainer {
  display: flex;
  justify-content: space-between;
  margin: 16px 22px;
}

.documentsActionButtonsContainer {
  display: flex;
  align-items: center;
}

.documentsAddFormsDropdownButton {
  padding: 10px 22px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  font-size: 13.33px !important;
}

.formCardsContainer {
  overflow-y: scroll;
  height: calc(100vh - 195px);
}

.innerFormCardsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 16px 24px;
  /* justify-content: center; */
}

.changeSequenceInnerFormCardsContainer {
  display: flex;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
}

.formDetailsCardContainer {
  margin: 8px;
  padding: 8px;
}

.formDetailCardActions {
  justify-content: space-between;
}

.formDetailsCardActionsRight {
  justify-content: flex-end;
}

.formDetailsCardContent {
  width: 250px;
  height: 85px;
  overflow: hidden;
  padding: 0 !important;
  margin: 8px !important;
}

.formDetailsCardContent .MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.4);
}

.singleNoteCardContent .MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.4);
}

.cardEditIconButton {
  margin: 0 16px !important;
}

.spinnerForDocumentEditorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 195px);
}

/*----- form/document management ends here ------------ */

/*--------- create/preview form starts here ---------*/
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px 32px;
}

.createFormTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createFormCloseIcon {
  position: absolute !important;
  right: 6px;
  top: 6px;
}

.createFormModalContent {
  display: flex;
  flex-direction: column;
  padding: 16px 44px !important;
}

.useTemplatesContentContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 44px 16px 44px !important;
}

.mgTop12 {
  margin-top: 12px !important;
}

.fieldNameContainer {
  flex: 1 1;
  margin-right: 24px !important;
}

.addFieldDataContainer {
  text-align: right;
  background-color: #f0f0f0;
  padding: 24px;
  border-radius: 8px !important;
}

.addFieldContainer {
  align-items: flex-end;
}

.flex1 {
  flex: 1 1 !important;
}

.mgLeft12 {
  margin-left: 12px !important;
}

.mgRight12 {
  margin-right: 12px !important;
}

.alignItemsCenter {
  align-items: center !important;
}

.dialogContentDivider {
  margin: 16px 0 !important;
}

.checkBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.titleTextField .MuiInputBase-root {
  font-size: 1.33rem !important;
}

.descriptionTextField .MuiInputBase-root {
  font-size: 1rem !important;
  margin-top: 5px !important;
}

.headerTitleContainer {
  margin: 8px 0 !important;
}

.formDataMainContainer {
  margin-top: 12px !important;
}

.mgBottom10 {
  margin-bottom: 10px !important;
}

.padding8 {
  padding: 8px 0 !important;
}

.checkBoxOptionContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 70%;
  flex-direction: column;
}

/* .schemaActionButtons{
  flex: 0.35;
} */

.addFieldActionsContainer {
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.iconButtonBorder {
  border: 1px solid #735996 !important;
  border-radius: 4px !important;
}

.formCardContainer {
  width: 800px;
  margin: 16px 8px;
}

.formCardActionContainer {
  position: absolute;
  right: 0;
}

.textAlignLeft {
  text-align: left !important;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginActionButton {
  margin: 8px 0;
}

.previewContainer {
  padding: 24px;
  border: 1px solid #e0e0e0;
}

.headerTitleContainer .MuiInput-underline:before,
.descriptionTextField .MuiInput-underline:before,
.headerTitleContainer .MuiInput-underline:after,
.descriptionTextField .MuiInput-underline:after,
.headerTitleContainer .MuiOutlinedInput-notchedOutline,
.descriptionTextField .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.requiredContainer {
  display: flex;
  align-items: center;
}

.whiteSpacePreLine {
  white-space: pre-line;
}

.signaturePad {
  height: 100px;
  width: 300px;
  border: dotted 1px grey;
  border-radius: 4px;
  margin-bottom: 4px;
}

.signPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  color: grey;
}

.imageUploaderContainer {
  height: 150px;
  width: 100%;
  border: 1px dashed gray;
  background: #ebeaf4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
}

.dropAreaText {
  display: flex;
  align-items: center;
}

.headerLogoDivider {
  margin: 32px 0 !important;
  width: 100% !important;
}

.createFormLogoImageContainer {
  width: 250px;
  height: 150px;
  object-fit: contain;
}

.headerLogoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.previewImageContainer {
  height: 100px;
  object-fit: contain;
}

.fofLogo {
  height: 30px;
  object-fit: contain;
}

.footerBorder {
  width: 100%;
  border-bottom: 2px solid #cfcfd9;
}

.footerContainer {
  display: flex;
  align-items: center;
  margin: 32px 0 14px 0;
}

.mgLeft4 {
  margin-left: 4px !important;
}

.mgTop2 {
  margin-top: 2px !important;
}

.mgLeft8 {
  margin-left: 8px !important;
}

.textColorGrey {
  color: grey;
}

.sigCanvas {
  border: 1px dashed gray;
  margin: 8px;
}

.numericContainer {
  display: flex;
  align-items: center;
}

.poweredByContainer {
  display: flex;
}

.createFormLogoAndNameContainer {
  display: contents;
}

.previewContainer .MuiDivider-root {
  width: 100% !important;
}

#documentEmptyPlaceHolderContainer {
  height: calc(100vh - 250px);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#documentEmptyPlaceHolderContainer .MuiTypography-root {
  color: black !important;
}

.useTemplatesEmptyPlaceholderContainer {
  height: calc(100vh - 284px);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.modalFormTitle {
  text-align: center;
}

/*--------- create/preview form starts here ---------*/
/* TA Button */

.ta-button .icon {
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ta-button .loading.icon,
.ta-button .success.icon,
.ta-button .error.icon {
  max-height: 14px;
  max-width: 14px;
}

.ta-button.loading .loading.icon,
.ta-button.success .success.icon,
.ta-button.error .error.icon {
  opacity: 1;
  height: 0.8em !important;
  width: 0.8em !important;
}

.ta-button.error {
  color: #f44336;
  border-color: #f44336;
}

.MuiButton-contained.ta-button.error {
  background-color: #f44336;
  color: #fff;
  border-color: #fff;
}

.ta-button.success {
  color: #802520;
  border-color: #802520;
}

.MuiButton-contained.ta-button.success {
  background-color: #802520;
  color: #fff;
  border-color: #fff;
}

.MuiButton-iconSizeMedium > *:first-child.flexRow {
  font-size: initial !important;
}

.taFlexRow {
  display: flex;
  flex-direction: "row";
}

/* TA Button ends */
.mgTopAndBottom24 {
  margin: 24px 0 !important;
}

.MuiCard-root label {
  font-size: 0.75rem;
}

/* Enterprise Client Details */
.enterpriseClientDetailMainContainer {
  padding: 0 15px 8px 15px;
}

.enterpriseClientDetailHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 130px;
}

.enterpriseClientDetailTitleContainer {
  display: flex;
  margin: 4px 16px;
  align-items: center;
}

.tabContentContainer {
  /* overflow-y: scroll; */
  height: calc(100vh - 164px);
}

.tabContentAssessmentContainer {
  height: calc(100vh - 165px);
}

.enterpriseClientDetailTabLabel.MuiTab-root {
  max-width: initial !important;
}

.enterpriseClientDetailTabLabel {
  flex: 1 1;
}

/* Enterprise Client Details */

/* Assign Coach Modal */
.assignCoachContentContainer {
  padding-bottom: 44px;
}

#assignCoachInput {
  border-bottom: none !important;
}

.assignCoachModalLoading {
  position: absolute;
  right: 280px;
  z-index: 11;
}

.documentsModalHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 44px 24px 44px !important;
}

.centeredLoading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.opaqueLoading {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.18);
}

.backgroundLight {
  background-color: rgba(0, 0, 0, 0.18);
}

.assignCoachInitialLoading {
  padding-bottom: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Assign Coach Modal */

/*Client Details*/
.tabsContentContainer {
  width: 100%;
}

.flexOne {
  flex: 1 1;
}

.tabContentHeaderContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#formsDropdown {
  min-width: 200px;
  position: fixed;
  top: 56px;
  right: 15px;
}

.innerContentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.innerContentContainerHeightAdjusted {
  margin-right: -8px;
}

.clientDetailsLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 102px);
}

.tabsContentContainer .innerFormCardsContainer {
  margin: 8px -8px !important;
}

/*Client Details*/
.previewFormLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
}

/*--------------------------------------------- notes-------------------------------------- */

.notesDateRangePickerContainer {
  display: flex;
  align-items: center;
}

.notesDateRangePickerCalendarIcon {
  position: absolute;
  right: 164px;
  cursor: pointer;
}

#addNoteButton {
  position: fixed;
  bottom: 24px;
  right: 24px;
}

.singleNoteImageCounterContainer {
  display: flex;
  align-items: center;
}

.singleNoteTitle {
  font-size: 16px !important;
  text-transform: none !important;
}

.singleNoteDescription {
  font-size: 17px !important;
  margin-top: 4px !important;
}

.notesEmptyPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notesMainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.notesHeaderAndButtoncontainer {
  display: flex;
  justify-content: flex-end;
}

.dateWiseNotesContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 108px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.loadingDateWiseContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 16px 0px 0px 0px;
  height: calc(100vh - 108px);
  overflow-y: scroll;
}

.singleDateWiseNotesContainer {
  margin: 16px 0px 8px 24px;
}

.singleNoteImageIcon {
  font-size: 20px !important;
  margin-right: 8px !important;
}

/* .noteTitleContainer .MuiInput-underline::before {
  border: unset !important;
} */

/* .noteTitleContainer .MuiInput-underline::after {
  border: unset !important;
} */

.noteTitleContainer .MuiInputBase-multiline {
  padding: 18.5px 14px !important;
}

.noteTitleContainer .Mui-disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}

.noteTitleTextField .MuiInputBase-root {
  font-size: 18px !important;
  margin: 8px 0;
}

.notesContainer {
  flex-wrap: wrap;
  display: flex;
  margin: 8px 8px 0px 8px;
}

.formDetailsCardHover:hover {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}

.floatRight {
  float: right;
}

.singleNoteCardActionArea {
  padding: 8px;
  cursor: pointer;
}

.formDetailsCardActionArea {
  cursor: pointer;
}

.mgRight4 {
  margin-right: 4px !important;
}

.mgBottom4 {
  margin-bottom: 4px !important;
}

.singleNoteActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px !important;
}

.singleNoteActionsNotEditable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3px !important;
}

.singleNoteTimestamp {
  color: #858484;
}

.singleNoteCardContent {
  width: 250px;
  height: 85px;
  overflow: hidden;
  padding: 0 !important;
  margin: 8px !important;
}

.colorPalette {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 126px;
}

.defaultColorIconButton {
  color: rgb(189, 186, 186) !important;
}

.noteEditorDialogActions {
  justify-content: space-between !important;
}

.noteActionIcons {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
}

.dateHeaderAndDividerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notesDateDivider {
  width: 100% !important;
}

.noteEditorContentArea {
  max-height: 72vh;
  overflow-y: scroll;
}

.noteListDate {
  margin-right: 8px !important;
}

.dateDivider {
  width: 100%;
  justify-content: center !important;
}

.notecloseIconButton {
  position: absolute !important;
  top: 0;
  right: 0;
}

/* -------------------------------------------Image uploader---------------------------------------------- */
.uploadIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#uploadImageText {
  font-size: 14px;
}

.imageActionButton {
  cursor: pointer;
}

.imageLoading {
  position: absolute;
  top: 54px;
  right: 80px;
  border-radius: 50%;
  text-align: center;
  background-color: #555555a8;
  cursor: pointer;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  width: 30px;
  height: 30px;
}

#uploadingImageCircularProgress {
  width: 20px;
  height: 20px;
}

.selectedImagesContainer {
  display: flex;
  flex-wrap: wrap;
}

.imageUploadMainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.uploadedImageContainer {
  width: 100px;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  height: 92px;
  flex-direction: column;
  padding-bottom: 0;
  margin: 8px 8px 8px 8px;
  border-radius: 4px;
}

.uploadedImageContainer img,
.uploadedImageContainer .noteImage {
  max-height: 78%;
  flex: 1 1;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.imageActionsNotes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 23.77px;
}

.addNotesUploadContainer {
  margin: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  -ms-flex-pack: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  min-height: 92px;
  cursor: pointer;
  border-radius: 4px;
}

.uploadingImageContainer {
  width: 100px;
  padding: 0px 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  height: 92px;
  flex-direction: column;
  margin: 8px;
  border-radius: 4px;
}

.uploadingImageContainer img,
.uploadingImageContainer .noteImage {
  max-height: 78%;
  flex: 1 1;
  object-fit: cover;
  background-size: cover;
  background-position: center;
}

.addUploadContainer:hover {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
}

#uploaderSectionLoader {
  width: 100%;
}

.collectionHeading {
  display: flex;
  margin-bottom: 16px;
  margin-top: 16px;
}

.imageUploaderSection .MuiPaper-root.MuiDialog-paper {
  min-width: 680px;
}

.uploaderSectionImageContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.uploadImageContainer:hover {
  cursor: pointer;
  max-height: 140px;
}

.noteEditorTitle {
  margin: 12px 0 !important;
  text-align: center !important;
}

.boldText {
  font-weight: 700 !important;
}

.loadMoreButtonContainer {
  width: 100%;
  justify-content: center;
  display: flex;
  margin: 8px;
  margin-top: 0px;
  max-height: 33px;
  align-items: center;
}

/* ------------------------------------------TA button------------------------------------ */
.ta-button .icon {
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ta-button .loading.icon,
.ta-button .success.icon,
.ta-button .error.icon {
  max-height: 14px;
  max-width: 14px;
}

.ta-button.loading .loading.icon,
.ta-button.success .success.icon,
.ta-button.error .error.icon {
  opacity: 1;
  height: 0.8em !important;
  width: 0.8em !important;
}

.ta-button.error {
  color: #f44336;
  border-color: #f44336;
}

.MuiButton-contained.ta-button.error {
  background-color: #f44336;
  color: #fff;
  border-color: #fff;
}

.ta-button.success {
  color: #802520;
  border-color: #802520;
}

.MuiButton-contained.ta-button.success {
  background-color: #802520;
  color: #fff;
  border-color: #fff;
}

.MuiButton-iconSizeMedium > *:first-child.flexRow {
  font-size: initial !important;
}

.sectionContainer {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 24px 0 24px 24px !important;
  flex-direction: column;
}

.previewContainer .MuiGrid-root .MuiBox-root .MuiDivider-root {
  display: none !important;
}

.previewFormContent .previewFormContainer {
  border: 1px solid #f0f0f0;
  padding: 24px !important;
  border-radius: 8px;
}

.sectionTitle {
  font-size: 18px !important;
}

.formDetailCardIcon {
  border: 1px solid #800520 !important;
  border-radius: 4px !important;
}

.formDetailCardChip {
  text-align: right;
}

.chipComponent {
  border: 0 !important;
  border-radius: 2px !important;
}

.greenBackground {
  background-color: rgba(0, 255, 0, 0.15) !important;
}

.redBackground {
  background-color: rgba(255, 0, 0, 0.15) !important;
}

.cardDeleteIconButton {
  margin-right: 4px !important;
}

.assignedSummaryContent {
  margin-top: 19px !important;
}

.tabContentWorkoutSummary #summaryCalendarContainer .fc-view-harness {
  height: calc(100vh - 160px) !important;
}

@media (min-width: 300px) and (max-width: 767px) {
  #landingAppBar {
    padding: 0 12px !important;
  }

  #arrowLogo {
    margin-right: 4px !important;
  }

  #textLogo {
    height: 20px;
  }

  .logoAndNameContainer {
    display: flex;
    flex-direction: column;
  }

  .logoImageContainer {
    width: 180px;
    height: 100px;
    object-fit: contain;
  }

  .logoFileName {
    font-size: smaller !important;
    margin-top: 8px !important;
  }

  .facilityInfoTextField .react-autosuggest__suggestions-list {
    max-width: 85% !important;
    width: 85% !important;
  }

  .profileContainer {
    padding-top: 48px !important;
    height: initial !important;
  }

  #updateProfileContainer {
    width: 100%;
    padding: 0 15px !important;
  }

  .enterpriseInviteNameContainer {
    flex-direction: column;
  }

  /*-------Add Member Modal starts here----------*/
  .editMemberButton {
    margin: 24px 8px !important;
  }

  .addMemberButton {
    margin: 24px 8px !important;
  }

  .memberFieldsContainer {
    flex-direction: column;
  }

  .firstRowFieldsContainer {
    flex-direction: column;
  }

  .secondRowFieldsContainer {
    flex-direction: column;
  }

  .memberInputFields {
    margin: 16px 0 !important;
  }

  .memberInputRegistrationField {
    margin: 16px 0 !important;
  }

  .addEditButtonContainers {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    justify-content: space-around;
    align-items: center;
  }

  .memberAddDialogTitle {
    text-align: left !important;
  }

  /*-------Add Member Modal ends here----------*/
  /*---------- Create/ Preview Form starts here -------*/
  .createFormTitle {
    padding: 8px 10px !important;
  }

  .headerTitle {
    margin-bottom: 16px !important;
  }

  .createFormModalContent {
    padding: 5px 16px !important;
  }

  .addFieldDataContainer {
    padding: 10px;
    text-align: right;
  }

  .addFieldActionsContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .displayFlex {
    justify-content: center;
  }

  .flexDirectionColumn {
    flex-direction: column !important;
  }

  .createFormTitle .MuiTypography-h6 {
    font-weight: 600 !important;
  }

  .headerTitleContainer .MuiOutlinedInput-input {
    font-size: 24px;
    font-weight: 500;
    padding: 0 !important;
  }

  .descriptionTextField .MuiInputBase-root {
    margin: 0 !important;
    padding: 0 !important;
  }

  .descriptionTextField .MuiOutlinedInput-inputMultiline {
    font-size: medium;
  }

  .fieldNameContainer {
    margin-bottom: 8px !important;
    margin-right: 0px !important;
  }

  .mgRight12 {
    margin-right: 0 !important;
  }

  .MuiIconButton-root .moreIconContainer {
    padding-left: 4px !important;
    padding-right: 1px !important;
  }

  .addFieldActionsContainer {
    margin-top: 8px !important;
  }

  .footerContainer {
    display: flex;
    justify-content: center;
  }

  .fofLogo {
    height: 25px;
    object-fit: contain;
    margin-right: 2px !important;
    margin-left: 4px !important;
  }

  .footerBorder {
    border-bottom: 1px solid #cfcfd9;
  }

  .previewContainer {
    padding: 10px;
  }

  .signaturePad {
    width: 100%;
  }

  .logoAndNameContainer {
    display: flex;
    flex-direction: column;
  }

  .logoImageContainer {
    width: 180px;
    height: 100px;
    object-fit: contain;
  }

  .logoFileName {
    font-size: smaller !important;
    margin-top: 8px !important;
  }

  .textPowered {
    font-size: 14px !important;
    margin-left: 2px !important;
    margin-top: 0 !important;
  }

  .textBy {
    font-size: 14px !important;
    margin-top: 0 !important;
  }

  .questionText {
    font-size: 16px !important;
  }

  .fieldTypeOptions {
    text-align: left;
  }

  .titleTextField .MuiOutlinedInput-root {
    padding: 0 !important;
  }

  .previewImageContainer {
    max-height: 60px;
  }

  .enterpriseClientDetailTabLabel {
    flex: auto;
  }

  /*-------- Create/Preview Form ends here--------*/
  #formsDropdown {
    position: absolute !important;
    width: 90% !important;
  }

  .enterpriseClientDetailHeaderContainer {
    flex-direction: column;
  }

  .enterpriseClientDetailTitleContainer {
    flex-direction: row !important;
  }

  .tabsContentContainer {
    margin: 8px !important;
  }

  .formDetailsCardContainer {
    width: 100% !important;
  }

  .tabContentContainer {
    height: calc(100vh - 155px) !important;
  }

  .singleDateWiseNotesContainer {
    margin: 8px !important;
  }

  .enterpriseClientDetailMainContainer {
    padding-top: 40px !important;
  }

  .enterpriseAssignerHeader {
    flex-direction: column-reverse !important;
  }

  .enterpriseClientDetailMainContainer .verticalCenter {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    /* position: absolute !important;
    right: 0 !important; */
  }

  .enterpriseAssignerHeader {
    top: 38px !important;
  }

  .notesDateRangePickerContainer {
    position: absolute;
  }

  .enterpriseAssignerHeader #repeatDaysToggle {
    position: absolute !important;
    right: -250px !important;
    top: -64px !important;
  }

  .tabContentWorkoutAssigner .fullWHListContainer {
    height: 100% !important;
  }
}

@media (min-width: 767px) and (max-width: 1023px) {
  .facilityInfoTextField .react-autosuggest__suggestions-list {
    max-width: 92% !important;
    width: 92% !important;
  }

  .facilityInfoCardContainer.MuiPaper-outlined {
    border: none !important;
  }

  .profileActionButton {
    text-align: left !important;
  }

  /*-------Add Member Modal starts here----------*/
  .memberAddDialog-mui {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .memberAddDialogContent {
    display: flex !important;
    flex-direction: column;
    width: 100%;
  }

  .fieldsContainer {
    width: 100%;
  }

  .addEditButtonContainers {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    justify-content: space-around;
    align-items: center;
  }

  .editMemberButton {
    margin: 24px 8px !important;
  }

  .addMemberButton {
    margin: 24px 8px !important;
  }

  .registrationFieldContainer {
    width: 100%;
  }

  .memberFieldsContainer {
    flex-direction: column;
    width: 100%;
  }

  .firstRowFieldsContainer {
    flex-direction: column;
  }

  .secondRowFieldsContainer {
    flex-direction: column;
  }

  .memberInputFields {
    margin: 16px 0 !important;
  }

  .memberInputRegistrationField {
    margin: 16px 0 !important;
  }

  .memberAddDialogTitle {
    margin-bottom: 8px !important;
  }

  /*-------Add Member Modal ends here----------*/
  /*---------- Create/ Preview Form starts here -------*/
  .createFormModalContent {
    padding: 28px !important;
  }

  .createFormTitle {
    padding: 16px;
  }

  .displayFlex {
    justify-content: space-evenly;
  }

  .createFormTitle .MuiTypography-h6 {
    font-weight: bold !important;
  }

  .textPowered {
    font-size: 20px !important;
    margin-left: 4px !important;
  }

  .textBy {
    font-size: 20px !important;
  }

  .fofLogo {
    height: 40px;
    object-fit: contain;
    margin-right: 4px !important;
    margin-left: 4px !important;
  }

  /*---------- Create/ Preview Form ends here -------*/
}

@media (min-width: 300px) and (max-width: 1023px) {
  .facilityInfoMainContainer {
    padding: 48px 0 !important;
  }

  .facilityInfoTextField {
    max-width: 100% !important;
    width: 100% !important;
    margin: 16px 0 !important;
  }

  .facilityInfoCardContentContainer {
    padding: 30px !important;
    padding-top: 0 !important;
  }

  .facilityInfoHeaderText {
    margin-bottom: 48px !important;
    width: 100%;
    padding: 0 30px !important;
  }

  .facilityInfoButtonContainer {
    justify-content: flex-start;
    padding: 0 30px !important;
  }

  .modalFormTitle {
    text-align: left;
  }

  #staffListContainer .listMessage {
    top: 50%;
  }

  .useTemplatesContent {
    height: calc(100vh - 120px);
  }

  .formDataMainContainer {
    flex: 1 1;
  }

  .enterpriseAssignerHeader {
    position: absolute !important;
    justify-content: flex-end;
    top: 130px !important;
  }

  .enterpriseAssignerHeader #searchAssignerInput {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .tabContentWorkoutAssigner #workoutAssignerList {
    margin-top: 24px !important;
  }

  .enterpriseClientDetailTitleContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  #workoutDaysList {
    overflow-y: scroll;
    padding-bottom: 60px;
  }

  .assignButtonContainer {
    position: absolute;
    right: 0;
    left: 0;
  }

  .tabContentWorkoutSummary #workoutSummaryLoader {
    top: 88px !important;
  }

  .tabContentAssessmentContainer {
    height: calc(100vh - 184px);
  }

  .tabContentContainer {
    height: calc(100vh - 184px);
  }

  #coachFilterDropdown {
    margin-top: 16px !important;
  }

  #clientStatusFilter {
    margin-top: 16px !important;
  }

  .workoutSummaryExercises {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .assignedSummaryContent {
    margin-top: 10px !important;
  }

  .enterpriseWorkoutDaySummary.fullWHListContainer {
    height: 100% !important;
    max-height: initial !important;
  }

  .tabContentWorkoutSummary #summaryCalendarContainer .fc-view-harness {
    height: calc(100vh - 180px) !important;
  }

  #searchExerciseFilter {
    margin-left: 4px !important;
  }

  .editorPanelTopRow .col-sm-12 {
    padding-right: 5px !important;
  }

  .filtersCollapsableButtonContainer {
    justify-content: center !important;
  }

  .editorPanelTopRow {
    align-items: center;
  }

  .MuiAutocomplete-popper {
    top: 258px !important;
  }
}

/* -----------------------------------------------------------------------pdfUploader------------------------------------------------------- */
.pdfThumbnailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formPdfViewerPreviewContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pdfUploaderContainer {
  flex: 1 1;
  margin-right: 24px !important;
}

.pdfUploaderContainer .uploadTitle {
  justify-content: flex-start;
}

/* ----------------------------------------------------------=-======--DATAPICKER--=====-=----------------------------------------------------- */
#clientDetailDOB .MuiFormControl-root {
  width: 100%;
}

#clientDetailDOB .MuiInput-underline:before,
#clientDetailDOB .MuiInput-underline:after {
  border-bottom: none !important;
}

#clientDetailDOB .MuiInput-underline {
  border-bottom: 1px solid !important;
}

#date-picker-inline {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.8) !important;
  border-bottom: none !important;
}

#date-picker-inline::-webkit-input-placeholder {
  color: #6c757c !important;
  opacity: 1 !important;
}

#date-picker-inline::placeholder {
  color: #6c757c !important;
  opacity: 1 !important;
}

/* ----------------------------------------------------------ASSESSMENTS------------------------------------------------------------------------ */
.noteAssessmentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.assessmentLoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.assessmentListContainer {
  flex-direction: column;
  display: flex;
  overflow-y: scroll;
  padding: 16px !important;
  padding-top: 46px !important;
  margin-bottom: 0px;
  scroll-behavior: smooth;
  flex: 1 1;
}

.loadingAssessmentListContainer {
  display: flex;
  height: 100%;
  justify-content: center;
}

.assessmentEditorBlock {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 4px !important;
}

.assessmentEditorContainer {
  display: flex;
  align-items: center;
  padding: 16px 8px;
  width: 100%;
}

.textContainer {
  flex: 1 1;
}

.summaryTextField {
  width: 100%;
}

.assessmentSummaryAndTimeContainer {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.assessmentImage {
  /* max-height: 78%; */
  flex: 1 1;
  object-fit: contain;
  background-size: cover;
  background-position: center;
}

.assessmentItemContainer {
  display: flex;
  flex-direction: column;
}

.assessmentItemInnerContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 8px 0;
  padding: 16px;
  width: 100%;
  position: relative;
  border: 1px solid #f0f0f0;
}

.noteItemInnerContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin: 8px 0;
  padding: 16px;
  width: 100%;
  position: relative;
  border: 1px solid #f0f0f0;
  transition: 250ms;
}

.noteItemInnerContainer:hover {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%),
    1px 1px 3px 1px rgb(60 64 67 / 15%);
  border: 1px solid #bfbfbf;
  cursor: pointer;
}

.assessmentMenuIcon {
  position: absolute !important;
  right: 4px;
  top: 4px;
}

#assessmentItemMenu .MuiPopover-paper {
  padding: 4px !important;
}

.attachedImageContainer {
  width: 100px;
  height: 100px !important;
  padding: 10px;
  background: #edf2f6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #d0dbe4;
  position: relative;
  flex-direction: column;
  margin: 8px 8px 8px 8px;
  border-radius: 4px;
}

.loadMoreAssessmentButton {
  position: absolute !important;
  bottom: 0;
  margin-top: 8px !important;
  z-index: 1 !important;
  right: 46%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.attachmentListContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  width: 100%;
}

.timestampContainer {
  text-align: left;
  color: rgb(97, 97, 97);
  margin-bottom: 16px;
  font-size: small;
  margin-left: 4px;
}

.assessmentTextFieldContainer {
  flex: 1 1;
  margin: 8px;
}

.attachmentIconButtonContainer {
  margin: 8px 12px 8px 0px;
}

.attachedFiesIconContainer {
  margin: 8px;
}

.addAssessmentButtonContainer {
  margin: 8px;
}

.assessmentEmptyPlaceholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fillFormButton {
  margin: 8px 8px 8px 0px !important;
}

#uploaderModalCloseButton {
  position: absolute;
  right: 0;
}

.uploaderTitle {
  margin: 12px 0 !important;
  text-align: center;
  font-weight: bold !important;
}

.uploadingPDF {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: -10px;
}

.pdfPreviewModalLoaderContainer {
  height: 600px;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.uploadedPDFThumbnailContainer {
  height: 100%;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 !important;
}

/* -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=formFilled skeleton=-=-=-=-==-=-=-=--=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */
.skeletonChipComponent.MuiSkeleton-root {
  display: inline !important;
}

.displayNone {
  display: none !important;
}

.noteImage .noteImage {
  height: 100% !important;
}

.noteImage .noteImage .react-pdf__message--loading {
  height: 100% !important;
}

.errorModalItem {
  padding: 16px !important;
  white-space: pre-wrap;
}

.errorModalLoader {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 24px;
}

.workoutAssignerRadioContainer {
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between;
}

.tabContentWorkoutAssigner #workoutAssignerList {
  padding-bottom: 0px !important;
}

.enterpriseAssignerHeader {
  position: fixed;
  top: 58px;
  left: 15px;
  right: 20px;
}

.tabContentWorkoutSummary .fc-header-toolbar {
  position: fixed;
  top: 58px;
  right: 20px;
  width: 50% !important;
}

.tabContentTrainerWorkoutSummary .fc-header-toolbar {
  position: absolute;
  top: 68px;
  right: 20px;
  width: 35% !important;
}

.tabContentWorkoutSummary #summaryCalendarContainer {
  margin: 0px !important;
}

.tabContentWorkoutSummary #workoutSummaryLoader {
  position: fixed;
  top: 62px;
  right: 20px;
}

#programAssignedModalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 36px !important;
}

#closeProgramAssignedIcon {
  position: absolute;
  top: 0;
  right: 0;
}

#programAssignedImage {
  width: 150px;
  height: 150px;
}

#programAssignedContentContainer {
  margin-left: 32px;
}

.mgBottom12 {
  margin-bottom: 12px !important;
}

.main-panel .MuiDrawer-paper,
.main-panel .MuiAppBar-root {
  z-index: 1050 !important;
}

.enterpriseClientDetailMainContainer .fullWHListContainer {
  height: calc(100vh - 265px) !important;
  overflow: hidden;
  margin-bottom: 10px !important;
}

.moreFiltersToggle {
  margin: 8px 0;
}

#inHouseClientsToggle {
  /* position: absolute; */
  right: 0;
  /* top: -60px; */
}

#inHouseToggleContainer {
  align-items: center;
}

#inHouseToggleContainer label {
  margin-bottom: 0px !important;
  color: gray !important;
}

#inHouseToggleContainer .MuiFormControlLabel-root {
  margin-right: 4px !important;
}

#shotsUploader {
  width: 100%;
  text-align: center;
  padding: 24px;
  background: #edf2f6;
  margin: 12px 0;
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 10%);
  border: 1px solid #d0dbe4;
}

.singleNoteDeleteButton {
  margin-left: 8px !important;
}

#staffMFACheckbox {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ta-button .MuiButton-label {
  margin-right: 4px;
}

.clientProgramNameHeader {
  font-weight: 600;
  position: relative;
  bottom: -1px;
}

.clientProgramName {
  display: flex;
  justify-content: flex-end;
}

#workoutDaysList {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
}

input#datePickerWidget {
  border-bottom: none !important;
}

/* ----------------------------------------ONLY LIVE WORKOUT SESSION && MEDIA QUERIES-------------------------------------- */
.sectionedWorkoutSession {
  width: 80%;
  padding: 8px 16px 6px 16px;
}

.viewWorkoutSessionSection {
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

.viewWorkoutSessionSectionHeader {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 16px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}

.workoutSessionSectionedExerciseCard {
  display: flex;
  margin-bottom: 10px;
  /* migrage */
}

.workoutSessionSectionedExerciseCard:hover {
  background-color: #eeeeee;
  border-radius: 4px;
  cursor: pointer;
}

.workoutSessionSectionedExerciseCardSelected {
  background-color: #e0e0e0;
  border-radius: 4px;
}

.workoutSessionSectionedExerciseCardSelected:hover {
  background-color: #e0e0e0;
}

#iniCardDoneContainer {
  display: flex;
  align-items: center;
}

.workoutSessionConfigMainContainer {
  display: flex;
  margin-right: 20px;
  margin-bottom: 5px;
}

.workoutSessionConfigImageKey {
  opacity: 0.6;
  display: flex;
  align-items: center;
}

.workoutSessionConfigKey {
  font-size: 14px;
  font-weight: 500;
}

.workoutSessionConfigValue {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.workoutSessionSectionedVideoPlayer {
  display: flex;
  position: relative;
  border-radius: 4px;
  border: solid 1px lightgrey;
}

.miniCardPoster {
  height: 96px;
  width: 96px;
  border-radius: 4px;
  background-color: #eee;
}

.sectionedExerciseConfig {
  margin-top: -4px;
}

.workoutSessionSectionedCardContent {
  flex: 1 1;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: space-around;
}

.workoutSessionSectionedExerciseNameLevel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

#homePage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#sectionDoneContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-right: 12px;
}

#exerciseCardMainContainer {
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  width: 600px;
  max-width: 100%;
}

#exerciseCardContentDetail {
  flex: 1 1;
  position: relative;
  overflow-y: overlay;
  /* max-height: calc(100vh - 62px - 56px - 48px); */
}

#exerciseCardContentDetail.MuiDialogContent-root {
  padding: 0 !important;
  /* min-width: 500px; */
}

#levelAndTimerContainer {
  display: flex;
  justify-content: space-between;
}

.workoutSessionExerciseEditContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.numberButtonEditDetailModal {
  border-radius: 50% !important;
  width: 36px !important;
  height: 36px;
  margin-left: 16px !important;
  min-width: initial !important;
  font-size: inherit !important;
}

.editExerciseDetailItemContainer {
  margin-left: 6px !important;
  max-width: 80px;
}

.editExerciseDetailItemContainer .MuiOutlinedInput-input {
  text-align: center;
  padding: 8px 4px;
  margin-top: 2px;
  min-width: 32px;
}

#timerContainer {
  display: flex;
  justify-content: center;
  /*border: 1px solid grey;*/
  border-radius: 4px;
  align-items: center;
  padding: 4px 6px;
}

#timer {
  margin-left: 8px;
}

#timeContainer {
  display: flex;
  justify-content: center;
}

#mainVideoContainer {
  /* padding-top: 100%; */
}

#mainVideoPlayer {
  overflow: hidden;
}

.react-player__preview {
}

.exerciseCardContentContainer {
  margin-top: 16px;
  padding: 16px;
}

.marginTop16 {
  margin-top: 16px !important;
}

.marginBottom4 {
  margin-bottom: 4px !important;
}

#feedbackToggleButton.MuiToggleButton-root.Mui-selected {
  background-color: #800520;
  color: #fff !important;
}

#feedbackToggleButton {
  /* background-color: #f4f4f4 !important ; */
  color: #800520 !important;
  width: 100%;
  font-size: inherit;
}

#feedbackToggleButton.colorDisabled {
  color: rgba(0, 0, 0, 0.12) !important;
}

.fontLarger {
  font-size: larger !important;
}

#sliderTextValues {
  display: flex;
  justify-content: space-between;
}

#feedbackPainSlider {
  padding: 0 18px 4px 18px;
}

#doneButton {
  background-color: white;
  padding-top: 16px;
  bottom: 0;
  padding-bottom: 16px;
}

#cancelButton {
  background-color: white;
  bottom: 0;
  padding-bottom: 14px;
}

.emptyImage {
  width: 400px;
  height: 400px;
}

#workoutCompleteDialog .MuiDialog-paperScrollPaper {
  border-radius: 12px;
}

.marginBottom18 {
  margin-bottom: 18px;
}

#completeModalContinueButton .MuiButton-label {
  text-transform: none;
}

#completeModalContinueBackButton .MuiButton-label {
  text-transform: none;
}

#completeModalContinueBackButton {
  margin-left: 8px;
}

#doneCompleteModalIcon {
  width: 100%;
  display: flex;
  justify-content: center;
}

#workoutCompleteModal {
  padding: 18px !important;
}

.marginTop8 {
  margin-top: 8px !important;
}

.marginTop24 {
  margin-top: 24px !important;
}

.mainVideoLoaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.noVideoPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.exerciseDetailNoteContainer {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.sectionedExerciseCardConfigValueTextfield {
  max-width: 48px;
  margin-left: 6px !important;
}

.sectionedExerciseCardConfigValueTextfield .MuiOutlinedInput-input {
  text-align: center;
  padding: 8px;
  margin-top: 2px;
}

.sectionedExerciseCardConfigValueDropdown {
  margin-left: 6px !important;
}

.sectionedExerciseCardConfigValueDropdown .MuiOutlinedInput-input {
  text-align: center;
  padding: 8px;
  margin-top: 2px;
}

#resistanceInputContainer {
  margin-bottom: 12px;
}

#miniCardDoneContainer {
  display: flex;
  align-items: center;
}

.miniCardDoneIcon.MuiSvgIcon-fontSizeLarge {
  font-size: 2.6rem !important;
}

.optionsUnit {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
  margin-left: 6px;
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .sectionedWorkoutSession {
    padding: 0;
  }

  #mainVideoPlayer {
    height: 330px !important;
  }
}

@media (min-width: 300px) and (max-width: 1023px) {
  .sectionedWorkoutSession {
    width: 90%;
  }

  .workoutSessionConfigMainContainer {
    margin-right: 12px;
  }
}

@media (min-width: 1365px) {
  #exerciseCardContentDetail.MuiDialogContent-root {
    min-width: 550px;
  }
}

@media only screen and (max-width: 940px) {
  #homePage {
    flex-direction: column;
    height: initial;
    align-items: center;
  }
}

@media only screen and (max-width: 1326px) {
  #exerciseCardMainContainer {
    /* margin-left: 0px;
    width: initial; */
  }
}

@media only screen and (min-width: 1560px) {
  #homePage {
    max-width: 1500px;
    margin: auto;
  }
}

#workoutAddNoteButton {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.workoutSessionTopRow {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sessionWorkoutName .MuiButton-label {
  font-size: 16px;
  font-weight: 600;
}

/*---------Choose Workout----------*/
.workoutChooseCard {
  height: 70px;
  width: 136px;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  background-color: #eeeeee;
  margin-right: 16px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.workoutChooseCard:hover {
  background-color: #e0e0e0;
}

.selectedWorkoutChooseCard {
  background-color: rgba(128, 37, 32, 0.2);
}

.selectedWorkoutChooseCard:hover {
  background-color: rgba(128, 37, 32, 0.25);
}

#workoutSwitcherEmptyPlaceHolder {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#workoutChooseContainer {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  max-width: 500px;
  min-width: 400px;
}

#workoutPreviewContainer {
  flex: 1 1;
  height: 500px;
  max-width: 500px;
  margin-top: 16px;
  overflow-y: scroll;
}

.previewSectionedWorkout {
  width: 100%;
}

#workoutCompleteDialog .MuiDialog-paperScrollPaper {
  border-radius: 12px;
}

.sectionedNoPoster {
  border-radius: 2px;
  height: 60px;
  width: 60px;
  background-color: #eee;
}

.sectionedPoster {
  border-radius: 2px;
  height: 60px;
  width: 60px;
}

.noteFilterContainer {
  position: absolute;
  right: 150px;
  display: flex;
  justify-content: flex-end;
  margin: 8px 20px 4px 0 !important;
  background-color: white;
  border-radius: 4px;
  z-index: 2;
}

.noteFilters {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px 20px 16px 20px;
}

.notesFilterReset {
  color: #800520;
}

.notesFilterReset:hover {
  opacity: 0.6;
  cursor: pointer;
}

.notesLoadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notesSingleFilterContainer {
  margin-top: 4px;
  display: flex;
  align-items: center;
}

#noteBadge .MuiBadge-anchorOriginTopLeftRectangle {
  top: 16px;
  left: 26px;
  border-radius: 4px 0 4px 0;
  font-size: 9px;
  min-width: 52px;
  height: 16px;
}

.createFormTypeOptionsContainer {
  margin: 16px;
}

.addFormIconButton:hover {
  color: #800520;
}

.clientDetailsButton {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.MuiToolbar-root.MuiToolbar-regular {
  min-height: initial !important;
  margin-top: 4px;
}

.createFormTypeOptionsContainer .MuiFormControlLabel-root {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.formsContentContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}
.fulltabform .MuiPaper-root {
  width: 100%;
}
.useFacilityLogoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.headerLogoVerticalDivider {
  margin: 8px 44px !important;
  height: 40%;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  width: 0;
}

.formImageUploaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formFacilityLogo {
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin-right: 24px;
}

.headerLogoSectionDividerContainer {
  text-align: center;
}

.addNoteAssessmentButton {
  position: absolute !important;
  right: 24px !important;
  z-index: 2;
  margin-block: 8px !important;
}

.editorHeaderContainer {
  display: flex;
  margin: 8px 0;
}

.clientDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.noteDescriptionTextField {
  margin: 8px 0 !important;
}

.headerDetailsEmailText {
  margin-right: 3px;
  color: gray;
}

.headerDetailsClientText {
  margin-left: 8px;
}

.headerDetailsDOBText {
  margin-right: 10px;
  color: gray;
}

.headerDetailsKey {
  font-weight: 500;
  color: #656565 !important;
}

.creatorText {
  margin-right: 4px;
}

.lockedContentText {
  font-size: 12px !important;
  display: flex;
  align-items: center;
}

.noteVersionsButtonText {
  color: #800520;
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.viewFormButtonText {
  color: #800520;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.viewFormButtonText:hover {
  text-decoration: underline;
}

.noteVersionsButtonText:hover {
  text-decoration: underline;
}

.disabledNoteVersionsButtonText {
  color: grey;
  font-size: 12px !important;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#noteEditorDialog .MuiDialog-paperWidthMd {
  max-width: 700px !important;
}

.versionsPane {
  width: 40% !important;
}

.versionsPaneContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.versionsPane .slide-pane__title-wrapper {
  margin-top: 2px;
}

.versionsPane .slide-pane__title {
  font-weight: 500 !important;
}

.expandedClose {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-left: auto;
}

.expandedOpen {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.noteVersionItem {
  border: solid 1px #e0e0e0;
  margin-bottom: 16px;
  padding: 16px 16px 4px 16px;
  width: 100%;
  border-radius: 4px;
}

.noteVersionItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submissionContentContainer {
  display: flex;
  justify-content: space-between;
}

.lockedContent {
  display: flex;
  align-items: center;
}

#currentVersionBadge .MuiBadge-anchorOriginTopRightRectangle {
  top: 8px;
  right: 26px;
  border-radius: 0 4px 0 4px;
  font-size: 9px;
  min-width: 52px;
  height: 16px;
  color: rgb(0, 73, 0);
  background-color: rgba(0, 255, 0, 0.28);
}

.lockedContentContainer {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.creatorLockedContentContainer {
  margin-left: 8px;
}

.creatorDetailsText {
  font-size: 12px !important;
}

.bold500 {
  font-weight: 500 !important;
}

.noteVersionCreatorDetails {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.exportIconContainer {
  position: absolute !important;
  right: 16px;
  top: 16px;
}

.autoFillSwitchContainer {
  display: flex;
  align-items: center;
}

.cursorDefault {
  cursor: default !important;
}

.noteVersionTimestamp {
  color: rgb(97, 97, 97);
  font-size: small;
}

.withLongText .MuiInputLabel-formControl {
  position: inherit !important;
}

.fullHeightAlignCenter {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formTypeDropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* ---------------------------------------------------------------- freehand note/add image css (start) ----------------------------------------------------------------*/
.freehandNotePreviewContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: grey;
  font-size: 0.7rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 8px;
}

.formImageWidgetPreview {
  height: 150px;
  width: 200px;
  border: dotted 1px grey;
  border-radius: 4px;
  margin-bottom: 4px;
  object-fit: contain;
}

.widgetQuestionText {
  color: grey;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.freehandNoteEditorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px lightgrey solid;
  border-radius: 0 0 4px 4px;
}

.imageEditorToolbarContainer {
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 4px 4px 0 0;
  height: 48px;
  width: 100%;
}

.brushSizeIconButtonContainer {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 4px;
}

.brushColorPaletteIcon {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  transition: 220ms;
  border: none;
  cursor: pointer;
}

.brushColorPaletteIconSelected {
  border-radius: 50%;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  transition: 220ms;
  border: none;
  cursor: pointer;
}

.brushColorPaletteIcon:hover {
  height: 38px;
  width: 38px;
}

.brushColorPaletteIconContainer {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
}

#imageSelectedBadge .MuiBadge-anchorOriginTopRightRectangle {
  top: 8px;
  right: 8px;
  color: rgb(31, 173, 0);
}

.addImageWidgetBackgroundImage {
  height: 240px;
  width: 356px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: dotted 1px grey;
  border-radius: 4px;
  margin-bottom: 4px;
}

.addImageCanvasImage {
  height: 240px;
  width: 356px;
  object-fit: contain;
}

/* ------------------------end of free hand note/add image widget css------------------- */

.addImageSectionContainer {
  text-align: left;
  margin-top: 16px;
}

/*------------my responsiveness----------*/
@media (max-width: 1250px) {
  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(1) {
    left: 17%;
  }

  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(2) {
    right: 17%;
  }

  .clientWorkModal .MuiDialog-paperWidthLg {
    max-width: 500px;
  }
}

@media (max-width: 991px) {
  .clienCircuitModal h2 {
    font-size: 3.1rem;
    line-height: 2.3rem;
  }

  .clienCircuitModal .circuitCont .MuiSvgIcon-fontSizeLarge {
    font-size: 8rem;
  }

  .clienCircuitModal div p {
    font-size: 1.8rem;
  }

  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(2) {
    right: 10%;
  }

  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(1) {
    left: 10%;
  }
}

@media (max-width: 767px) {
  .sectionedExerciseCardConfigValueTextfield {
    max-width: 40px !important;
  }

  .sectionedExerciseCardConfigValueTextfield .MuiInputBase-input {
    font-size: 12px;
  }

  .sectionedExerciseCardConfigValueTextfield
    .MuiSelect-outlined.MuiSelect-outlined {
    font-size: 12px;
  }

  #miniCardDoneContainer .miniCardDoneIcon.MuiSvgIcon-fontSizeLarge {
    font-size: 2rem !important;
  }

  .MuiSelect-select.MuiSelect-select {
    padding-right: 27px !important;
  }

  .clienCircuitModal h2 {
    font-size: 2.5rem;
    line-height: 2rem;
    margin-bottom: 22px;
  }

  .clienCircuitModal .circuitCont .MuiSvgIcon-fontSizeLarge {
    font-size: 6rem;
  }

  /* .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(2) {
    right: 15%;
  }
  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(1) {
    left: 15%;
  } */
  .clienCircuitModal div p {
    font-size: 1.6rem;
  }

  #exerciseCardMainContainer {
    width: 370px;
  }

  .numberButtonEditDetailModal {
    margin-left: 3px !important;
  }

  .clientWorkModal .MuiDialog-paperScrollPaper > .MuiButtonBase-root {
    width: 40px;
    height: 40px;
  }

  #timerContainer .MuiIconButton-sizeSmall {
    padding: 7px !important;
  }

  .editExerciseDetailItemContainer .MuiOutlinedInput-input {
    width: 40px;
  }

  #exerciseConfig .MuiButton-outlined {
    padding: 5px 5px;
  }

  .numberButtonEditDetailModal {
    width: 33px !important;
    height: 33px;
  }
}

@media (max-width: 575px) {
  .workoutSessionExerciseEditContainer {
    flex-direction: column;
  }

  .workoutSessionExerciseEditContainer #timeContainer {
    margin-top: 20px;
  }

  .workoutSessionExerciseEditContainer .numberButtonEditDetailModal {
    margin-left: 8px !important;
  }

  #sliderTextValues .MuiTypography-body2 {
    font-size: 14px !important;
  }

  .customListing ul li:nth-of-type(1) {
    flex-direction: column;
  }

  .customListing ul li:nth-of-type(1) .clientCardAvatar {
    margin-right: 0;
  }

  .customListing ul li:nth-of-type(1) .MuiAvatar-root {
    width: 33px;
    height: 33px;
  }

  .customListing ul li:nth-of-type(1) p {
    margin-bottom: 0;
    font-size: 12px;
  }

  #exerciseCardMainContainer {
    width: 290px;
  }
}

@media (max-width: 480px) {
  .customListing ul li:nth-of-type(1) .MuiAvatar-root {
    width: 27px;
    height: 27px;
  }

  .workoutSessionConfigKey {
    font-size: 12px;
  }

  .miniCardPoster {
    height: 75px;
    width: 75px;
  }

  .workoutSessionSectionedCardContent {
    padding-left: 10px;
  }

  .clientWorkModal .MuiDialog-paperScrollPaper > .MuiButtonBase-root {
    width: 32px;
    height: 32px;
  }

  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(1) {
    left: 2%;
  }

  .clientWorkModal
    .MuiDialog-paperScrollPaper
    > .MuiButtonBase-root:nth-of-type(2) {
    right: 2%;
  }

  .modalArrow .MuiSvgIcon-fontSizeLarge {
    font-size: 1.2rem;
  }

  #exerciseCardMainContainer {
    width: 260px;
  }
}

/*   Chat System style start from here  */
.chatsystem {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 95%;
  margin: 0 auto;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: lightgray;
}

.chat::-webkit-scrollbar {
  width: 0px;
}

.chatsystem .row {
  display: flex;
  flex-wrap: nowrap;
}

.chatsystem .MuiToggleButton-root.Mui-selected {
  color: #ffffff !important;
  background-color: rgb(128, 5, 32) !important;
}

.ClientMessageList {
  overflow: hidden;
  width: 55%;
}

.client-chat-list {
  height: 75vh;
  overflow-y: auto;
  border-right: 1px solid lightgray;
  border-radius: 5px 0 0 5px;
}

.customRow {
  border-bottom: 1px solid lightgray;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.client-info {
  display: grid;
  align-items: center;
  /*font-weight: bold;*/
  grid-template-columns: 0.5fr 1fr 2fr 1fr 1fr;
}

.chat_list .counter {
  position: relative;
  top: 9px;
  left: 24px;
  width: 23px !important;
  background: #b42546;
  border-radius: 50px;
  color: white;
  text-align: center !important;
}

.client-chat {
  display: grid;
  margin-block: 11px;
  grid-template-columns: 0.5fr 1fr 4fr;
}

.ClientMessageList i {
  margin-left: auto;
}

.ChatRoom {
  width: 45%;
}

.ChatRoom h4 {
  background-color: rgb(128, 5, 32);
  width: 35%;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
  padding: 10px 0px;
  color: #fff;
  border-radius: 10px;
  margin-block: 5px;
}

.msg_history {
  background-color: rgb(244, 234, 235);
  border-radius: 20px;
  width: 70%;
  margin: 0 auto;
  padding: 10px 10px;
}

.filterbutton {
  margin-bottom: 5px;
  padding: 0;
  margin-left: 15px;
}

.chat {
  overflow-y: auto;
  height: 60vh;
}

.chat p {
  font-weight: bold;
  color: rgb(181, 37, 70);
}

.msg_history p.chat-date {
  color: #800520;
  font-weight: 500;
  margin: 20px 0px;
  text-align: center;
  clear: both;
}

.msg_history p.chat-date:before,
.msg_history p.chat-date:after {
  background-color: #800520;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 25%;
}

.msg_history p.chat-date:before {
  right: 1em;
  margin-left: -50%;
}

.msg_history p.chat-date:after {
  left: 1em;
  margin-right: -50%;
}

.incoming_msg {
  width: 85%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.incoming_msg p {
  background: white;
  border-radius: 10px;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  word-break: break-word;
}

.time_date {
  color: rgb(181, 37, 70);
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}

.outgoing_msg {
  width: 85%;
  overflow: hidden;
  float: right;
}

.sent_msg {
  float: right;
}

.sent_msg p {
  word-break: break-word;
  background: white;
  border-radius: 10px;
  font-size: 14px;
  padding: 5px 10px 5px 12px;
}

.input_msg_write {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

input.write_msg {
  border: 1px solid grey !important;
  border-radius: 12px !important;
  width: 100%;
}

.msg_send_btn {
  background: #800520;
  border: medium none;
  border-radius: 10px !important;
  color: #fff;
  cursor: pointer;
  height: 33px;
  padding: 0px 31px 0px 19px;
}

@media only screen and (max-width: 1200px) {
  .msg_history {
    width: 90%;
  }
}

@media only screen and (max-width: 991px) {
  .chatsystem {
    display: block;
  }

  .ClientMessageList {
    overflow: hidden;
    width: 100%;
  }

  .ChatRoom {
    margin-top: 20px;
    width: 100%;
  }

  .msg_history {
    width: 90%;
  }
}

@media only screen and (max-width: 590px) {
  .client-info {
    grid-gap: 10px;
    gap: 10px;
  }

  .client-chat-list {
    margin-top: 10px;
  }

  .client-info span {
    font-size: 12px;
  }

  .client-chat {
    grid-gap: 10px;
    gap: 10px;
    margin-left: 45px;
  }

  .ChatRoom h4 {
    width: 45%;
  }
}

/* Client detail Page Message Center Tab Style Start From Here */
.MessageCentertab {
  margin: 10px 0px;
}

.MessageCentertab .ChatRoom {
  width: 100%;
}

.MessageCentertab .ChatRoom h4 {
  width: 15%;
}

.MessageCentertab .ChatRoom .msg_history {
  width: 90%;
}

.type_msg {
  margin-top: 10px !important;
}

.MessageCentertab .ChatRoom .chat,
.MessageCentertab .ChatRoom .type_msg {
  width: 90%;
  margin: 0 auto;
}

/*---------------------------AR Styles Start From Here----------------------*/
.UploadBlogImage {
  justify-content: start;
  flex-direction: column;
}

g .NewBlog {
  width: 100%;
}

/* .MuiDialog-paperFullWidth {
  width: 30% !important;
} */
ul {
  padding: 0px !important;
}

.chatsystemtabs.row {
  width: 95%;
  margin: 0 auto;
}

.chatsystemtabs .MuiToggleButton-root.Mui-selected {
  color: #ffffff !important;
  background-color: rgb(128, 5, 32) !important;
}
.addStripe {
  padding: 0px !important;
  width: 100%;
  margin-top: 10px;
}
.stripeHeader {
  width: 85%;
  margin: 0 auto;
  margin-top: 40px;
}
.stripeHeader h4 {
  color: #999999;
  font-weight: 650;
}
.stripeHeader h4:last-child {
  margin: 0;
  font-weight: 580;
}
.stripeCardList {
  border-radius: 0px;
  margin-bottom: 0px;
}
.stripeModalFooter {
  width: auto;
  margin: 0px 15px 10px 15px;
  border: 1px solid #d3d3d3;
  border-top: none !important;
}

/* Mobile only */
@media only screen and (min-width: 300px) and (max-width: 767px) {
  #enterpriseLogo {
    margin-right: 0px;
  }

  .hideOnMobile {
    display: none;
  }

  .form-check {
    margin-bottom: 14px;
  }

  .termsAndCaptchaContainer {
    flex-direction: column !important;
  }

  .verifiyEmail {
    max-width: 80% !important;
  }

  #workoutAssignerTabs .nav.nav-tabs {
    width: 100% !important;
  }

  .editCardDetailButton {
    /* position: relative;
      left: 85px;
      bottom: 130px; */
    width: 100%;
  }

  .editCardNote {
    width: 85% !important;
    margin-left: -10px;
  }

  #fileUploadedDiv {
    margin-left: 9px !important;
  }

  #feedbackForm.MISSING_EXERCISE .modal-footer {
    margin-bottom: 8px;
  }

  #feedbackForm.MISSING_EXERCISE {
    max-width: 86% !important;
    top: 50% !important;
  }

  /* #exerciseFilters {
      margin-right: 18px;
    } */

  .editorPanelTopRow .col {
    margin-top: 5px !important;
  }

  /* .datePicker {
    margin-right: 7px !important;
  } */

  .verticalCenter {
    line-height: 30px !important;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .fullWidth {
    max-height: -webkit-max-content;
    max-height: max-content;
    width: 100% !important;
  }

  #clientEditorList {
    margin-left: 0px !important;
  }

  #enterpriseEditorList {
    margin-left: 0px !important;
  }

  #clientDetailWorkoutSummary {
    padding: 0px !important;
  }

  #clientDetailAssignCoach {
    padding: 0px !important;
  }

  .clientDetailContainer {
    margin-top: 30px;
  }

  .enterpriseDetailContainer {
    margin-top: 30px;
  }

  .assignerModal .modal-content {
    width: initial;
  }

  .workoutDaysHeader {
    display: grid;
  }

  #clientEditorList .decPadding {
    padding: 8px !important;
  }

  #enterpriseEditorList .decPadding {
    padding: 8px !important;
  }

  #clientEditorList {
    margin-left: 8px;
  }

  #enterpriseEditorList {
    margin-left: 8px;
  }

  .clientEditor .nav.nav-tabs {
    justify-content: space-around;
    border-bottom: 1px solid #eee;
    margin-bottom: 0px !important;
    padding-bottom: 16px !important;
  }

  .enterpriseEditor .nav.nav-tabs {
    justify-content: space-around;
    border-bottom: 1px solid #eee;
    margin-bottom: 0px !important;
    padding-bottom: 16px !important;
  }

  #clientEditorListContainer .nav-tabs > .nav-item > .nav-link {
    margin: 0;
    margin-right: 5px;
    padding: 0px 0px !important;
    line-height: 1.5;
  }

  #enterpriseEditorListContainer .nav-tabs > .nav-item > .nav-link {
    margin: 0;
    margin-right: 5px;
    padding: 0px 0px !important;
    line-height: 1.5;
  }

  #mobileStyleCreatePractitioner {
    margin-top: 12px;
    flex-direction: column;
  }

  #mobileStyleLink {
    margin-left: 0 !important;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .signupBody .logo {
    width: 220px;
    margin-top: 64px;
    margin-bottom: 0;
  }

  .loginBody,
  .signupBody,
  .profileBody {
    height: 100vh;
    margin: auto;
  }

  .exerciseInfo {
    font-size: 0.45rem !important;
  }

  .videoReplayButton {
    top: -165px !important;
  }

  .videoReplayButton:hover {
    background-color: transparent !important;
  }

  /* .exerciseCard .details {
      max-width: 100%;
    } */

  .exerciseCard .card-title .exerciseName {
    margin-right: 0px;
    padding-left: 5px;
    font-size: 0.5rem;
  }

  .exerciseCard .level {
    padding-right: 5px !important;
  }

  .exerciseCard .level > p {
    font-size: 0.5rem !important;
  }

  .exerciseCardLevel {
    font-size: 0.5rem !important;
  }

  .summaryContentHeading {
    font-size: 0.65rem !important;
  }

  .workoutDayArrow {
    margin-right: -20px !important;
  }

  .filterPadding {
    margin: 8px 0 !important;
    padding: 0 !important;
  }

  .signupBody .loginCard {
    width: 100%;
    padding: 0px 0px;
    margin-bottom: auto;
    box-shadow: none !important;
  }

  .clientEditor h3.card-title {
    padding-left: 10px;
  }

  .enterpriseEditor h3.card-title {
    padding-left: 10px;
  }

  .dropdown-style {
    margin-top: 12px !important;
  }

  .dropdown-menu {
    background-color: white !important;
    border: solid 0.5px lightgrey !important;
  }

  .button-small-margin {
    margin-right: 18px;
    max-width: 100% !important;
    text-align: center !important;
  }

  #workoutDesigner {
    min-height: 650px;
  }

  .selectedExercisesContainer {
    margin-top: 40px;
    min-height: 500px !important;
    padding: 8;
    overflow-y: auto;
    overflow-x: auto;
    right: 0;
    top: 0;
  }

  /* .exerciseEditCard {
      max-width: 75%;
    } */

  .exerciseEditCard .index {
    position: absolute;
    left: -18px;
    margin-left: -8px;
    margin-bottom: 8px;
  }

  .exerciseEditCard .videoContainer {
    position: relative;
    left: -18px;
  }

  .dayText {
    font-size: 1.6rem;
  }

  .clientDetailCard {
    width: 100%;
  }

  .clientEditor h3.card-title {
    padding-left: 10px;
  }

  .enterpriseEditor h3.card-title {
    padding-left: 10px;
  }

  .exerciseEditCard {
    flex-direction: column;
    width: initial;
  }

  .exerciseDetailsInputs {
    min-width: 250px;
    margin: 16px 0;
  }

  #assignerTopRow {
    padding: initial 32px;
  }

  #assignerTopRow .assignButton {
    margin: 8px 0;
  }

  #errorsModal {
    margin-top: 36px !important;
  }

  #errorsModal .modal-body {
    padding-top: 0;
  }

  #errorsModal .modal-footer {
    padding-top: 8px;
    padding-bottom: 0;
  }

  .exerciseEditorModal,
  #feedbackForm.MISSING_EXERCISE {
    margin-top: 36px !important;
  }

  .exerciseEditorModal .modal-body,
  #feedbackForm.MISSING_EXERCISE .modal-body {
    padding-top: 0;
  }

  .exerciseEditorModal .modal-footer,
  #feedbackForm.MISSING_EXERCISE .modal-footer {
    padding-top: 8px;
    padding-bottom: 0;
  }

  #posterUploadContainer {
    padding-left: 29px;
  }

  .bulkUploadButtonContainer {
    position: relative;
  }
  #programForm {
    padding: 10px 20px;
    margin-right: 0 !important;
  }
  .programPackageFieldWidth {
    width: 100% !important;
  }
  /* Designer Responsive Css starts here */
  .filterQueryTextPadding {
    padding: 0;
  }
  #searchExerciseFilter {
    margin: 0 !important;
  }
  .filtersCollapsableButtonContainer {
    margin: 8px 0 !important;
    padding: 0 !important;
    justify-content: center;
  }
  #filterContainer {
    padding: 0 !important;
  }
  #workoutDesignerDetails {
    padding: 0 !important;
    height: 36px;
  }
  #deisgnerTopRow {
    padding: 0 !important;
  }
  #deisgnerTopRow #exerciseFiltersContainer {
    width: 100%;
  }
  #exerciseFilters {
    width: 100%;
    margin: 0;
  }
  #saveFilterButton {
    text-align: center;
    margin-top: 8px;
  }
  #draftFilterButton {
    text-align: center;
    margin-top: 8px;
  }
  #designerContainer {
    flex-direction: column;
  }
  .workoutDesignerListContainer {
    width: 280px;
    max-width: initial;
  }
  .exerciseEditCard .title {
    margin-left: 8px;
    font-size: 12px !important;
    max-width: 120px !important;
  }
  .feedbackButton {
    top: calc(50% + 120px);
  }
  .floatingButton {
    bottom: 60px;
  }
  /* Designer Responsive Css Ends here */
  /* Assigner Responsive Css Starts here */
  .mobileFullWidth {
    width: 100% !important;
  }
  #repeatDaysToggle {
    width: 100%;
    text-align: start;
  }
  #searchAssignerInput .form-group {
    width: 100%;
  }
  .addDayAssignerCard {
    padding: 6px !important;
  }
  .nav-item {
    padding: 0;
    max-width: 100px;
  }
  .nav-tabs {
    padding: 0;
  }
  .assignedWorkoutText {
    max-width: 35%;
  }
  .workout-list-button {
    flex: 1 1;
    max-width: none !important;
  }
  #workoutTypeSelector {
    width: 100%;
    flex-direction: column;
  }
  #workoutTypeSelector .form-group {
    width: 100%;
  }
  .topClientList {
    padding: 0 !important;
  }
  #workoutDaySelect {
    margin-right: 0;
    width: 100%;
  }
  .workoutDaysList {
    height: auto !important;
    max-height: none !important;
  }
  #forMobileView {
    margin: 8px 0 !important;
  }
  .clientSMCard,
  .selectedCard {
    height: 80px !important;
  }
  #forMobileView .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
  .assignerModal {
    width: 94vw;
  }
  .clientDetailContainer {
    min-height: auto;
  }
  .enterpriseDetailContainer {
    min-height: auto;
  }
  .mobilOverFlowHidden {
    overflow: hidden !important;
  }
  .mobileHeightInitial {
    height: initial !important;
  }
  .mobileAlignItemsNone {
    align-items: unset !important;
  }
  .marginAuto {
    margin: auto !important;
  }

  /* Assigner Responsive Css Ends here */
}

.flexRow {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

#uploadingImageCircularProgress {
  width: 20px;
  height: 20px;
}

.uploadManager {
  text-align: left;
}

.uploadedDoc {
  align-items: center;
  margin-bottom: 4px;
  background-color: white;
  margin: 12px 0;
  padding: 8px 12px;
  border-radius: 6px;
}

.uploadedDoc .deleteIcon {
  margin-right: 8px !important;
  object-fit: initial;
  height: initial;
  margin-top: initial;
}

.uploadedDoc .deleteIcon:hover {
  cursor: pointer;
  -webkit-filter: initial;
  filter: initial;
}

.uploadedDoc .flexRow {
  align-items: center;
}

.uploadedDoc .uploadedFileName {
  margin-left: 8px;
}

.uploadTitle {
  margin-bottom: 8px !important;
}

.dropzoneCard {
  padding: 0;
}

.pickerSection {
  border: 1px dotted #800520;
  border-radius: 6px;
  text-align: center;
}

.dropzone {
  padding: 20px 0 !important;
}

.pickerSection button {
  text-transform: initial !important;
  padding: 0;
  color: #800520;
  padding-left: 6px !important;
}

.pickerSection button:hover {
  background-color: white !important;
  color: #800520 !important;
}

.dropAreaText {
  display: flex;
  align-items: center;
  justify-content: center;
}

