/* Autocomplete styling Starts here------------------------------------------------------ */
.CUSTOM__map-maker-icon {
  color: #fd6c6c;
}

.CUSTOM__github-icon {
  font-size: 24px;
}

.CUSTOM__github-link {
  color: #262626;
  font-size: 20px;
}

.CUSTOM__github-link:hover,
.CUSTOM__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.CUSTOM__suggestion-icon {
  margin-right: 8px;
}

.CUSTOM__search-bar-container {
  width: 100%;
}

.CUSTOM__search-input-container {
  position: relative;
}

.CUSTOM__search-input,
.CUSTOM__search-input:focus,
.CUSTOM__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.CUSTOM__clear-button,
.CUSTOM__clear-button:active,
.CUSTOM__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.CUSTOM__autocomplete-container {
  max-width: 328px;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
}

.CUSTOM__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.CUSTOM__suggestion-item--active {
  background-color: #fafafa;
}

.CUSTOM__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.CUSTOM__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.CUSTOM__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.CUSTOM__error-message {
  color: red;
  text-align: left;
}

.CUSTOM__geocode-result-header {
  font-size: 20px;
  color: #222222;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

/* Autocomplete styling Ends here------------------------------------------------------ */

/*------------------------ONBOARDING STYLES START--------------------*/

.onboardingContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 92vh;
  border-top: solid 1px lightgrey;
  background-color: white;
}

.onboardingLeftSide {
  flex: 0.15;
}

.onboardingMainContent {
  flex: 0.6;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  flex-direction: column;
  box-shadow: 10px 0 5px -2px #f0f0f0;
}

.onboardingRightSide {
  position: relative;
  flex: 0.25;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.pageCount {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 150px;
  padding: 15px 15px 40px 40px;
  font-size: 52px;
  font-weight: 600;
  color: lightgrey;
  background-color: #f8f8f8;
}

.onboardingButtonContainer {
  display: flex;
  padding-bottom: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.goBackContainer {
  display: flex;
  width: 100%;
  text-align: right;
  justify-content: flex-end;
  color: gray;
  padding-right: 44px;
}

.onBoardingHeading {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 500;
}

.onBoardingSubHeading {
  font-size: 20px;
  font-weight: 500;
  color: #802520;
}

.goBackContainer:hover {
  color: black;
  cursor: pointer;
}

.onBoardingSectionHeader {
  color: #802520;
  text-align: left;
  padding-bottom: 20px;
}

.onBoardingSubHeaderContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 10px;
}

.educationAndCertificationsContainer {
  flex-direction: column;
  padding-right: 26px;
}

.educationAndCertificationsInnerContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.servicesContainer {
  flex-direction: column;
  padding-right: 26px;
}

.documentsContainer {
  flex-direction: column;
  padding-right: 26px;
}

.documentsSubHeader {
  margin-bottom: 10px;
  margin-top: 44px;
}

.servicesInnerContainer {
  display: flex;
  flex: 1;
  margin-top: 10px;
  flex-direction: column;
}

.documentsInnerContainer {
  display: flex;
  flex: 1;
  margin-top: 10px;
  flex-direction: column;
}

.addNewtext {
  color: #707070;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
}

.educationAndCertificationsAddNew {
  margin-top: 8px;
}

.addNewtext:hover {
  cursor: pointer;
  opacity: 0.5;
}

.educationCardsContainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
}

.educationCard {
  display: flex;
  position: relative;
  outline: none;
  border: none !important;
  margin: 10px 10px;
  width: 30%;
  min-width: 250px;
  background-color: #f0f0f0 !important;
}

.educationCancel {
  position: absolute;
  top: 14px;
  right: 16px;
}

.educationCardMainText {
  width: 88%;
  display: flex;
  flex-direction: column;
  color: #802520;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.educationCardSecText {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  margin-bottom: 2px;
}

.educationEditCancel {
  position: absolute;
  top: 8px;
  right: 8px;
}

.serviceEditCancel {
  position: absolute;
  top: 8px;
  right: 8px;
}

.educationEdit {
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.educationCardContent {
  flex: 1;
  padding: 16px;
  min-height: 116px;
  display: flex;
  flex-direction: column;
}

.educationReferenceLink {
  margin-top: 16px !important;
  font-style: italic;
  font-weight: bold !important;
}

.educationFieldsContainer {
  padding: 20px;
  width: 400px;
}

.serviceFieldsContainer {
  padding: 20px 40px 40px 40px;
  width: 400px;
}

.serviceField {
  padding-bottom: 16px;
}

.educationField {
  padding-bottom: 16px;
}

.certificationEdit {
  text-align: end;
  bottom: 6px;
  right: 6px;
}

.certificationCardsContainer {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  padding-top: 20px;
}

.selectAllText {
  color: #03a9f4;
  font-size: 16px;
}

.unselectAllText {
  font-size: 16px;
  color: grey;
}

.selectAllText:hover,
.unselectAllText:hover {
  cursor: pointer;
  text-decoration: underline;
}

.onboardingServicesList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
}

.customServiceChip
  .MuiSvgIcon-root.MuiChip-deleteIcon.MuiChip-deleteIconColorPrimary {
  width: 14px;
  height: 14px;
}

.fileUploaderContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.uploaderContainer {
  flex: 0.45;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.uploadManager {
  width: 100%;
}

.uploadManager .uploadTitle {
  font-size: 14px;
  min-width: 80%;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.uploaderText {
  font-size: 14px;
  min-width: 80%;
  height: 40px;
  margin-right: 16px;
  background-color: #f0f0f0;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.aboutTextArea:hover {
  border-color: black !important;
}

.aboutTextArea:focus {
  border-color: #802520 !important;
  border-width: 2px;
}

.fileUploadParent {
  display: flex;
  flex-direction: column;
}

.filePickerLabel {
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  background: #f0f0f0;
}

.filePickerLabel:hover {
  cursor: pointer;
}

.proPicContainer {
  width: 150px;
  padding-top: 150px;
  position: relative;
  cursor: pointer;
  border: 3px solid white;
  box-shadow: 2px 2px 2px 2px #aba9a9;
  border-radius: 50%;
  box-sizing: content-box;
}

.proPicContainer img {
  /* object-position: top; */
  background-color: #e0e0e0;
}

.proPicLabel {
  position: absolute;
  top: 45%;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  color: grey;
}

.onBoardingTabs {
  display: none;
}

.onboardMenuButton {
  display: none !important;
}

.onboardingFieldContainer {
  flex: 1;
  width: 90%;
}

.rightSideInnerContainer {
  width: 75%;
}

.onboardingMainButton {
  margin-bottom: 20px !important;
  height: 50px;
}

.letsCreateText {
  padding-right: 6px;
  font-weight: 600;
}

.fieldContainer {
  padding: 10px 20px;
  padding-left: 0;
}

.MuiOutlinedInput-input {
  border-bottom: none !important;
}

.onboardingServicesList .MuiChip-label {
  font-weight: 600;
}

.mg-rt-32 {
  margin-right: 32px;
}

.mg-tp-32 {
  margin-top: 32;
}

.mg-tp-bt-32 {
  margin: 32px 0 !important;
}

.mg-tp-bt-16 {
  margin: 16px 0 !important;
}

.marginTop16 {
  margin-top: 16px !important;
}

.programDropdownHeight .fortisFormaDropDownSelect__placeholder,
.programDropdownHeight .fortisFormaDropDownSelect__single-value {
  font-size: 14px;
}

.extrasChips .MuiOutlinedInput-adornedStart {
  padding-left: 8px;
}

.previewButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.stepOneOnboardMainContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 10px;
}

.stepOneOnboardInnerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.certificateImgContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.certificateImg {
  width: 115px;
  height: 150px;
  object-fit: contain;
}

.orText {
  width: 100%;
  display: flex;
  justify-content: center;
  color: grey;
  padding-bottom: 16px;
}

.onBoardingServicesSubHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 10px;
}

/*-------Preview Styles-------*/
.pd-top-16 {
  padding-top: 16px;
}

.pd-top-44 {
  padding-top: 44px;
}

.pd-bottom-16 {
  padding-bottom: 16px;
}

.mg-left-16 {
  margin-left: 16px;
}

.trainersContainer {
  height: 70vh;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.trainerCardRadius {
  border-radius: 6px;
}

.trainerCard {
  margin: 0;
  width: 400px;
  min-height: 520px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.trainerCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.trainerCardContentContainer {
  padding: 8px;
}

#exampleProgram .trainerCard,
#exampleProgram .trainerCardContentContainer {
  padding-top: 0;
}

#exampleProgram .trainerProgram:nth-child(2) {
  margin-top: 0;
}

.trainerProfilePic {
  width: 80px !important;
  height: 80px !important;
  object-fit: contain;
}

.trainerProfilePic img {
  object-position: top;
  background-color: #e0e0e0;
}

.trainerChip {
  height: 20px !important;
  font-weight: 500;
  margin: 0px 2px;
  border: 1px solid #800520 !important;
}

.trainerChip span {
  padding: 0 6px !important;
}

.trainerInfoTitle {
  color: grey;
}

.certificateIcon {
  height: 14px !important;
  width: 14px !important;
  margin-left: 4px;
  margin-bottom: -3px;
  cursor: pointer;
}

.trainerProfileTopContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trainerMainDetails {
  margin-left: 16px;
}

.trainerProfileButtons {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
}

.educationList {
  padding-inline-start: 15px;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.pricingContainer {
  flex: 1;
  text-align: left;
  align-self: center;
}

.programPrice {
  display: flex;
  align-items: baseline;
}

.programIncl {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  margin-top: -6px;
}

.trainerProgramNameContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.greyColor {
  color: grey;
}

.redColor {
  color: #800520;
}

.contactFormField {
  width: 100%;
  margin-top: 16px !important;
}

.trainerPrograms {
  padding: 8px 8px;
}

.trainerProgram {
  margin-top: 42px;
  margin-bottom: 4px;
}

.trainerProgram:nth-child(2) {
  margin-top: 12px;
}

.trainerPackage {
  border: 1px solid #e0e0e0;
  padding: 4px 16px;
  margin-top: 8px;
  border-radius: 4px;
  left: -2px;
  position: relative;
}

.letterSpacingInc {
  letter-spacing: 1px !important;
}

.programsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#programsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;
}

#programsHeader button {
  margin-right: 32px;
}

.packageNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#programsHeader .onBoardingSectionHeader {
  padding: 0;
  margin: 0;
}

/*Only Mobile*/
@media only screen and (min-width: 300px) and (max-width: 767px) {
  .onboardingMainContent {
    display: block !important;
    flex: initial !important;
    overflow-y: initial !important;
  }
  .onboardingButtonContainer {
    padding: 0 !important;
    flex-direction: row !important;
    justify-content: space-between;
  }

  .onboardingMainButton {
    height: 60px !important;
    margin-bottom: 0 !important;
    width: 50%;
    border-radius: 0 !important;
  }

  .rightSideInnerContainer {
    width: 100%;
  }

  .pageCount {
    display: none;
  }

  .onboardingContainer {
    height: initial !important;
    flex-direction: column !important;
  }

  .hideItem {
    display: none;
  }

  .onboardingRightSide {
    flex: initial !important;
  }

  .onboardingFieldContainer {
    flex: 1;
    width: 100%;
  }

  .letsCreateText {
    display: block;
    font-size: 28px !important;
    font-weight: 600;
  }

  .goBackContainer {
    text-align: left !important;
    justify-content: flex-start !important;
    padding-left: 20px;
  }

  .onBoardingSectionHeader {
    padding-left: 20px;
    font-size: 1.5rem;
  }

  .onBoardingSubHeaderContainer {
    padding-left: 20px;
  }

  .educationCardsContainer {
    flex-direction: column;
  }

  .educationAndCertificationsContainer {
    padding-right: 0 !important;
  }

  .educationCard {
    width: 96% !important;
  }

  .educationFieldsContainer,
  .serviceFieldsContainer {
    max-width: 300px;
  }

  .servicesContainer {
    padding-right: 0 !important;
    padding-left: 24px !important;
  }

  .fileUploaderContainer {
    flex-direction: column !important;
    padding-left: 16px;
  }

  .documentsContainer {
    padding-right: 0 !important;
  }

  .uploaderText {
    margin-bottom: 5px;
  }

  .uploaderContainer {
    margin-top: 20px;
  }

  .onboardingServicesList {
    padding-left: 16px;
  }

  .onboardMenuButton {
    display: block !important;
  }

  .onboardMenu.visible {
    transform: translateX(-300px);
  }

  .onboardMenu {
    position: fixed;
    background: #fafafa;
    z-index: 1000;
    position: fixed;
    will-change: transform;
    transition: all 0.25s ease-in;
  }

  .imageContainer {
    display: none;
  }

  .proPicContainer {
    width: 200px;
    height: 100px;
    padding-top: 100px;
  }

  .proPicLabel {
    top: 43%;
    font-size: 1rem;
  }

  .educationAndCertificationsContainer {
    flex-direction: column;
  }

  .educationAndCertificationsInnerContainer {
    max-width: 100vw;
  }

  .servicesInnerContainer {
    max-width: 100vw;
  }

  .documentsInnerContainer {
    max-width: 100vw;
  }

  .servicesContainer {
    flex-direction: column;
  }

  .documentsContainer {
    flex-direction: column;
  }

  .StepFourUploadContainer {
    flex-direction: column;
  }

  .filePickerLabel {
    padding: 20px;
  }

  #onBoarding .MuiGrid-container {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .drawerListItem {
    padding-left: 5px !important;
  }

  .stepOneOnboardMainContainer {
    flex-direction: column;
  }

  .stepOneOnboardInnerContainer {
    flex-direction: column;
  }

  .trainerCard {
    width: initial !important;
  }

  #programsHeader {
    padding-left: 24px !important;
  }
}

/*Only IPad*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .onboardMenuButton {
    display: block !important;
  }

  .onboardMenu.visible {
    transform: translateX(-300px);
  }

  .onboardMenu {
    position: fixed;
    background: #fafafa;
    z-index: 1000;
    position: fixed;
    will-change: transform;
    transition: all 0.25s ease-in;
  }

  .imageContainer {
    display: none;
  }

  .proPicContainer {
    width: 200px;
    height: 100px;
    padding-top: 100px;
  }

  .proPicLabel {
    top: 43%;
    font-size: 1rem;
  }

  .educationAndCertificationsContainer {
    flex-direction: column;
  }

  .educationAndCertificationsInnerContainer {
    max-width: 100vw;
  }

  .servicesContainer {
    flex-direction: column;
  }

  .documentsContainer {
    flex-direction: column;
  }

  .servicesInnerContainer {
    max-width: 100vw;
  }

  .documentsInnerContainer {
    max-width: 100vw;
  }

  .StepFourUploadContainer {
    flex-direction: column;
  }

  .filePickerLabel {
    padding: 20px;
  }

  #onBoarding .MuiGrid-container {
    max-height: 80vh;
    overflow-y: scroll;
  }

  .stepOneOnboardMainContainer {
    flex-direction: column;
  }

  .stepOneOnboardInnerContainer {
    flex-direction: column;
  }

  .trainerCard {
    width: initial !important;
  }
}
