.flexRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
}

#uploadingImageCircularProgress {
  width: 20px;
  height: 20px;
}

.uploadManager {
  text-align: left;
}

.uploadedDoc {
  align-items: center;
  margin-bottom: 4px;
  background-color: white;
  margin: 12px 0;
  padding: 8px 12px;
  border-radius: 6px;
}

.uploadedDoc .deleteIcon {
  margin-right: 8px !important;
  object-fit: initial;
  height: initial;
  margin-top: initial;
}

.uploadedDoc .deleteIcon:hover {
  cursor: pointer;
  -webkit-filter: initial;
  filter: initial;
}

.uploadedDoc .flexRow {
  align-items: center;
}

.uploadedDoc .uploadedFileName {
  margin-left: 8px;
}

.uploadTitle {
  margin-bottom: 8px !important;
}

.dropzoneCard {
  padding: 0;
}

.pickerSection {
  border: 1px dotted #800520;
  border-radius: 6px;
  text-align: center;
}

.dropzone {
  padding: 20px 0 !important;
}

.pickerSection button {
  text-transform: initial !important;
  padding: 0;
  color: #800520;
  padding-left: 6px !important;
}

.pickerSection button:hover {
  background-color: white !important;
  color: #800520 !important;
}

.dropAreaText {
  display: flex;
  align-items: center;
  justify-content: center;
}
